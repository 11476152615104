import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Box,
    Typography,
    Grid,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Checkbox,
    FormControlLabel,
    Button,
    Card,
    CardContent,
    Chip,
    Link,
    ListItemText,
    IconButton,
    Collapse,
    Snackbar,
    Autocomplete
} from '@mui/material';
import { Alert } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { selectFactoryData } from '../../selectors/factory.selector';
import { updateFactoryData } from '../../reducers/factory.reducer';
import SaveIcon from '@mui/icons-material/Save';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import moment from 'moment';


const FacilityDataTab = () => {
    const dispatch = useDispatch();
    const factoryData = useSelector(selectFactoryData);
    const [formData, setFormData] = useState({});
    const [initialData, setInitialData] = useState({});
    const [expandedSections, setExpandedSections] = useState({});
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  
    useEffect(() => {
        if (factoryData) {
            const processedData = JSON.parse(JSON.stringify(factoryData), (key, value) => {
                // Check if the value is a date string
                if (typeof value === 'string' && moment(value, moment.ISO_8601, true).isValid()) {
                    // Format it to YYYY-MM-DD
                    return moment.utc(value).format('YYYY-MM-DD');
                }
                return value;
            });
            setFormData(processedData);
            setInitialData(processedData);
            
            // Initialize all sections as expanded
            const allExpanded = sections.reduce((acc, section) => {
                acc[section.name] = true;
                return acc;
            }, {});
            setExpandedSections(allExpanded);
        }
    }, [factoryData]);

    const handleChange = (name, value) => {
        setFormData(prevData => {
          const newData = JSON.parse(JSON.stringify(prevData));
          const keys = name.split('.');
          let current = newData;
          for (let i = 0; i < keys.length - 1; i++) {
            if (!current[keys[i]]) current[keys[i]] = {};
            current = current[keys[i]];
          }
        //   // Handle both single values and arrays
        //     if (Array.isArray(value)) {
        //         // For multi-select, ensure we're setting an array
        //         current[keys[keys.length - 1]] = value;
        //     } else if (value === null || value === '') {
        //         // For clearing a value
        //         current[keys[keys.length - 1]] = '';
        //     } else {
        //         // For single select or text input
        //         current[keys[keys.length - 1]] = value;
        //     }
          current[keys[keys.length - 1]] = value;
          return newData;
        });
      };
  
      const deepCompare = (obj1, obj2) => {
        if (obj1 === obj2) return true;
        if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 == null || obj2 == null) return false;
        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);
        if (keys1.length !== keys2.length) return false;
        for (const key of keys1) {
          if (!keys2.includes(key) || !deepCompare(obj1[key], obj2[key])) return false;
        }
        return true;
      };
      
      const handleSubmit = (event) => {
        event.preventDefault();
        const changes = Object.keys(formData).reduce((acc, key) => {
          if (!deepCompare(formData[key], initialData[key])) {
            acc[key] = formData[key];
          }
          return acc;
        }, {});
            
        if (Object.keys(changes).length > 0) {
            dispatch(updateFactoryData({
                organisationId: factoryData.organisationId,
                supplierId: factoryData.supplierId,
                factoryId: factoryData._id,
                changes,
            }));
            setSnackbarMessage('Changes saved successfully');
        } else {
            setSnackbarMessage('No changes detected');
            setSnackbarSeverity('info');
            setSnackbarOpen(true);
        }
        setSnackbarOpen(true);
      };
    
    const getValue = (obj, path) => {
        return path.split('.').reduce((acc, part) => acc && acc[part], obj);
    };

    const renderField = (field) => {
      let value = getValue(formData, field.dbMapping) || '';
      
      switch (field.fieldType) {
        case 'string':
          return (
            <TextField
              fullWidth
              size="small"
              label={field.fieldLabel}
              name={field.dbMapping}
              value={value}
              onChange={(e) => handleChange(field.dbMapping, e.target.value)}
              margin="dense"
              variant="outlined"
            />
          );
        case 'dropdown':
            // Convert single-item arrays to string and handle invalid values
            if (Array.isArray(value) && value.length === 1) {
                value = value[0];
            }
            // if (!field.dropdownOptions.includes(value)) {
            //     value = '';
            // }
            return (
                <FormControl fullWidth size="small" margin="dense">
                {/* <InputLabel>{field.fieldLabel}</InputLabel> */}
                {/* <Select
                    name={field.dbMapping}
                    value={value}
                    onChange={(e) => handleChange(field.dbMapping, e.target.value)}
                    label={field.fieldLabel}
                >
                    <MenuItem value="">
                    <em>None</em>
                    </MenuItem>
                    {field.dropdownOptions.map((option) => (
                    <MenuItem key={option} value={option}>{option}</MenuItem>
                    ))}
                </Select> */}
                <Autocomplete
                freeSolo
                options={field.dropdownOptions}
                value={value || null}
                onChange={(event, newValue) => {
                    handleChange(field.dbMapping, newValue);
                }}
                onInputChange={(event, newInputValue, reason) => {
                    // This handles the case when user types and doesn't select from dropdown
                    if (reason === 'input') {
                        handleChange(field.dbMapping, newInputValue);
                    }
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={field.fieldLabel}
                        variant="outlined"
                        size="small"
                        fullWidth
                        margin="dense"
                    />
                )}
            />
                </FormControl>
            );
        case 'dropdown multi':
            // Ensure value is always an array and only contains valid options
            // value = Array.isArray(value) ? value : [value].filter(Boolean);
            // value = value.filter(v => field.dropdownOptions.includes(v));
            let arrayValue = Array.isArray(value) 
                ? value 
                : typeof value === 'string' 
                    ? value.split(',').map(v => v.trim()).filter(Boolean)
                    : [];
            return (
                <FormControl fullWidth size="small" margin="dense">
                    <Autocomplete
                multiple
                freeSolo
                options={field.dropdownOptions}
                value={Array.isArray(value) ? value : value ? [value] : []}
                onChange={(event, newValue) => {
                    const updatedValue = typeof value === 'string' 
                        ? newValue.join(', ')
                        : newValue;
                    handleChange(field.dbMapping, newValue);
                }}
                onInputChange={(event, newInputValue, reason) => {
                    // if (reason === 'input') {
                    //     const updatedValue = [...value, newInputValue];
                    //     handleChange(field.dbMapping, updatedValue);
                    // }
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={field.fieldLabel}
                        variant="outlined"
                        size="small"
                        fullWidth
                        margin="dense"
                    />
                )}
                renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                        <Chip
                            label={option}
                            {...getTagProps({ index })}
                            key={option}
                        />
                    ))
                }
            />
                {/* <InputLabel>{field.fieldLabel}</InputLabel>
                <Select
                    multiple
                    name={field.dbMapping}
                    value={value}
                    onChange={(e) => handleChange(field.dbMapping, e.target.value)}
                    label={field.fieldLabel}
                    renderValue={(selected) => selected.join(', ')}
                >
                    {field.dropdownOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                        <Checkbox checked={value.indexOf(option) > -1} />
                        <ListItemText primary={option} />
                    </MenuItem>
                    ))}
                </Select> */}
                </FormControl>
            );
        case 'notes':
            return (
                <TextField
                    fullWidth
                    multiline
                    rows={4}
                    size="small"
                    label={field.fieldLabel}
                    name={field.dbMapping}
                    value={value}
                    onChange={(e) => handleChange(field.dbMapping, e.target.value)}
                    margin="dense"
                    variant="outlined"
                />
            );
        case 'bool':
          return (
            <FormControlLabel
              control={
                <Checkbox
                  checked={value}
                  onChange={(e) => handleChange(field.dbMapping, e.target.checked)}
                  name={field.dbMapping}
                  sx={{ pt: 2 }}
                />
              }
              label={field.fieldLabel}
            />
          );
        // case 'date':
        //     return (
        //         <LocalizationProvider dateAdapter={AdapterDateFns}>
        //             <DatePicker
        //                 label={field.fieldLabel}
        //                 sx={{ width: "100% " }}
        //                 value={value ? new Date(value) : null}
        //                 onChange={(newValue) => handleChange(field.dbMapping, newValue)}
        //                 renderInput={(params) => {
        //                 <TextField 
        //                 {...params} 
        //                 size="extra-small" 
        //                 margin="dense" sx={{ 
        //                 '& .MuiInputBase-root': { 
        //                     height: '40px'  // Adjust this value to match other fields
        //                 } 
        //                 }}
        //                 />
        //             }}
        //             />
        //         </LocalizationProvider>
        //     );

        case 'date':
            return (
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                        label={field.fieldLabel}
                        value={value ? moment.utc(value) : null}
                        sx={{ width: "100% " }}
                        onChange={(newValue) => {
                            // Format the date to ISO string, but only keep the date part
                            const formattedDate = newValue ? newValue.format('YYYY-MM-DD') : null;
                            handleChange(field.dbMapping, formattedDate);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size="small"
                                margin="dense"
                                fullWidth
                                sx={{
                                    '& .MuiInputBase-root': {
                                        height: '40px'
                                    }
                                }}
                            />
                        )}
                    />
                </LocalizationProvider>
            );    
        case 'number':
            return (
                <TextField
                fullWidth
                size="small"
                label={field.fieldLabel}
                name={field.dbMapping}
                value={value}
                onChange={(e) => handleChange(field.dbMapping, e.target.value)}
                margin="dense"
                variant="outlined"
                type="number"
                />
            );
        case 'file':
        return (
            <Box>
            <Typography variant="caption">{field.fieldLabel}</Typography>
            {value ? (
                <Link href={value} target="_blank" rel="noopener noreferrer">
                View File
                </Link>
            ) : (
                <Typography variant="body2">No file uploaded</Typography>
            )}
            </Box>
        );
        case 'none':
            return null; // This will create empty spaces
        default:
          return null;
      }
    };
  
    const renderSection = (sectionName, fields) => (
        <Card sx={{ mb: 2, border: '1px solid #e0e0e0' }}>
            <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Typography variant="h6">{sectionName}</Typography>
                    <IconButton onClick={() => toggleSection(sectionName)}>
                        {expandedSections[sectionName] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                </Box>
                <Collapse in={expandedSections[sectionName]}>
                    <Grid container spacing={2}>
                        {fields.map((field) => (
                            <Grid item xs={12} sm={field.gridSize?.sm || 6} md={field.gridSize?.md || 3} key={field.dbMapping}>
                                {renderField(field)}
                            </Grid>
                        ))}
                    </Grid>
                </Collapse>
            </CardContent>
        </Card>
    );

    const toggleSection = (sectionName) => {
        setExpandedSections(prev => ({
            ...prev,
            [sectionName]: !prev[sectionName]
        }));
    };

    const sections = [
        {
            name: 'Facility Basic Information',
            fields: [
                { fieldLabel: 'Facility Name', dbMapping: 'name', fieldType: 'string', gridSize: { sm: 6, md: 3 } },
                { fieldLabel: 'Supplier Name', dbMapping: 'metadata.supplierName', fieldType: 'string' },
                { fieldLabel: 'Facility Type', dbMapping: 'metadata.processInfo.supplierType', fieldType: 'dropdown', dropdownOptions: ['Finished Goods', 'Subcontractor'] },
                { fieldLabel: 'Reporting?', dbMapping: 'metadata.reportingCheck', fieldType: 'bool' },
                { fieldLabel: 'DeMinimis?', dbMapping: 'metadata.deMinimis.deMinimisCheck', fieldType: 'dropdown', dropdownOptions: ['Yes', 'No'] },
                { fieldLabel: 'If, Yes to DeMinimis, enter year.', dbMapping: 'metadata.deMinimis.deMinimisYear', fieldType: 'string' },
                { fieldLabel: 'Facility Legal Name (Local Language, per Business License)', dbMapping: 'metadata.facilityLegalName', fieldType: 'string' },
                { fieldLabel: 'Domestic/International', dbMapping: 'metadata.domesticOrInternational', fieldType: 'dropdown', dropdownOptions: ['Domestic', 'International'] },
                { fieldLabel: 'Ref Facility ID', dbMapping: 'metadata.factoryId', fieldType: 'string' },
                { fieldLabel: 'OS ID', dbMapping: 'metadata.ids.osId', fieldType: 'string' },
                { fieldLabel: 'Wordly/Higg ID', dbMapping: 'metadata.ids.higgId', fieldType: 'string' },
                { fieldLabel: 'ZDHC ID', dbMapping: 'metadata.ids.zdhcId', fieldType: 'string' },
                { fieldLabel: 'Facility Address', dbMapping: 'metadata.address', fieldType: 'string', gridSize: { sm: 12, md: 6 } },
                { fieldLabel: 'Facility Country', dbMapping: 'metadata.country', fieldType: 'string' },
                { fieldLabel: 'City', dbMapping: 'metadata.city', fieldType: 'string' },
                //{ fieldLabel: 'Whitespace', dbMapping: '', fieldType: 'none' },
                { fieldLabel: 'Agent Contact Name', dbMapping: 'metadata.agentContact.name', fieldType: 'string' },
                { fieldLabel: 'Agent Email', dbMapping: 'metadata.agentContact.email', fieldType: 'string' },
                { fieldLabel: 'Agent HQ Address', dbMapping: 'metadata.agentContact.address', fieldType: 'string' },
                { fieldLabel: 'Agent Phone Number', dbMapping: 'metadata.agentContact.phone', fieldType: 'string' },
                { fieldLabel: 'Primary Facility Contact Name', dbMapping: 'metadata.primaryFacilityContactPerson.name', fieldType: 'string' },
                { fieldLabel: 'Primary Facility Contact Title', dbMapping: 'metadata.primaryFacilityContactPerson.position', fieldType: 'string' },
                { fieldLabel: 'Primary Facility Contact Email', dbMapping: 'metadata.primaryFacilityContactPerson.email', fieldType: 'string' },
                { fieldLabel: 'Primary Facility Contact Phone', dbMapping: 'metadata.primaryFacilityContactPerson.phone', fieldType: 'string' },
                { fieldLabel: 'Social Compliance Contact Name', dbMapping: 'metadata.socialComplianceContactPerson.name', fieldType: 'string' },
                { fieldLabel: 'Social Compliance Contact Email', dbMapping: 'metadata.socialComplianceContactPerson.email', fieldType: 'string' },
                { fieldLabel: 'Social Compliance Contact Phone', dbMapping: 'metadata.socialComplianceContactPerson.phone', fieldType: 'string' },
                { fieldLabel: 'Whitespace', dbMapping: '', fieldType: 'none' },
                { fieldLabel: 'EHS Compliance Contact Name', dbMapping: 'metadata.ehsContactPerson.name', fieldType: 'string' },
                { fieldLabel: 'EHS Compliance Contact Email', dbMapping: 'metadata.ehsContactPerson.email', fieldType: 'string' },
                { fieldLabel: 'EHS Compliance Contact Phone', dbMapping: 'metadata.ehsContactPerson.phone', fieldType: 'string' },
                { fieldLabel: 'Whitespace', dbMapping: '', fieldType: 'none' },
                { fieldLabel: 'Materials/Sustainability Contact Name', dbMapping: 'metadata.materialsSustainabilityContactPerson.name', fieldType: 'string' },
                { fieldLabel: 'Materials/Sustainability Contact Email', dbMapping: 'metadata.materialsSustainabilityContactPerson.mail', fieldType: 'string' },
                { fieldLabel: 'Materials/Sustainability Contact Phone', dbMapping: 'metadata.materialsSustainabilityContactPerson.phone', fieldType: 'string' },
                { fieldLabel: 'Whitespace', dbMapping: '', fieldType: 'none' },
                { fieldLabel: 'Language(s) Spoken by Facility Contacts', dbMapping: 'metadata.facilityContactLanguages', fieldType: 'string',  gridSize: { sm: 9, md: 6 } },
            ]
        },
        {
            name: 'Facility Status',
            fields: [
                { fieldLabel: 'STATUS OF COMPLIANCE', dbMapping: 'metadata.complianceStatus', fieldType: 'dropdown', dropdownOptions: ['AUTHORIZATION LAPSED', 'AUTHORIZED', 'AUTHORIZED BUT NOT FULLY ONBOARDED', 'NEVER ONBOARDED- ON HOLD BY PRODUCTION', 'NOT AUTHORIZED', 'NOT CURRENT VENDOR', 'ON HOLD BY PRODUCTION', 'PENDING AUTHORIZATION', 'PROBATIONARY PERIOD', 'TEMPORARY AUTHORIZATION'] },
                { fieldLabel: 'VENDOR RATING', dbMapping: 'metadata.vendorRating', fieldType: 'dropdown', dropdownOptions: ['GREEN', 'YELLOW', 'ORANGE', 'RED', 'NOT MONITORED'] },
                { fieldLabel: '# of CAPs Open', dbMapping: 'metadata.CAP.openCapCount', fieldType: 'number' },
                { fieldLabel: 'Date of Compliance Authorization', dbMapping: 'metadata.miscDates.complianceAuthorizationDate', fieldType: 'date' },
                { fieldLabel: 'Supply Chain Status', dbMapping: 'metadata.supplyChainStatus', fieldType: 'dropdown', dropdownOptions: ['Grow', 'Maintain', 'Phase Out', 'Hold', 'Pending Offboarding'] },
                { fieldLabel: 'Supply Chain Priority', dbMapping: 'metadata.supplyChainPriority', fieldType: 'dropdown', dropdownOptions: ['High', 'Low', 'Medium', 'Unknown'] },
                //{ fieldLabel: 'Supply Chain Contact', dbMapping: 'metadata.supplyChainContact', fieldType: 'string' },
                { fieldLabel: 'Potential Initial PO Date', dbMapping: 'metadata.potentialInitialPODate', fieldType: 'date' },
                { fieldLabel: 'Whitespace', dbMapping: '', fieldType: 'none' },
                { fieldLabel: 'Facility will enter IRSS Program?', dbMapping: 'metadata.irss.check', fieldType: 'bool' },
                { fieldLabel: 'IRSS Program End Date', dbMapping: 'metadata.irss.programEndDate', fieldType: 'date' },
                { fieldLabel: 'IRSS Status', dbMapping: 'metadata.irss.status', fieldType: 'dropdown', dropdownOptions: ['Waiting to be enrolled', 'Currently enrolled', 'Program ending soon', 'Program ended'] },
                { fieldLabel: 'Whitespace', dbMapping: '', fieldType: 'none' },
                { fieldLabel: 'Date of Last Assessment', dbMapping: 'metadata.assessmentDates.lastAssessmentDate', fieldType: 'date' },
                { fieldLabel: 'Type of Last Social Assessment', dbMapping: 'metadata.socialAssessmentInfo.lastSocialAssessmentType', fieldType: 'dropdown', dropdownOptions: ['ABVETEX', 'Better Work', 'Brand Audit', 'BSCI', 'Development audit', 'ERSA', 'ERSA-FLC', 'ERSA-FLC 3.0', 'Fair Trade', 'Higg VFSLM', 'SA8000', 'SMETA 2-Pillar', 'SMETA 4-Pillar', 'SQP', 'Verified SAQ (Elevate)', 'WCA', 'WRAP', 'No Assessment'] },
                { fieldLabel: 'Name of Audit Firm', dbMapping: 'metadata.auditingFirm', fieldType: 'dropdown', dropdownOptions: ['Algi', 'Arche Advisors', 'Benchmarks Co. Ltd', 'Better Work', 'Bureau Veritas (BV)', 'CSR Solutions Limited', 'ELEVATE', 'ESTS', 'Intertek (ITS)', 'Labor Law', 'Omega', 'SGS', 'UL'] },
                { fieldLabel: 'Payer of Last Social Assessment', dbMapping: 'metadata.socialAssessmentInfo.lastSocialAssessmentPayer', fieldType: 'dropdown', dropdownOptions: ['Reformation', 'Other-Brand', 'Other - Accommodation'] },                { fieldLabel: 'Next Assessment Date Window - Start', dbMapping: 'metadata.nextAssessmentWindowDateStart', fieldType: 'date' },
                { fieldLabel: 'Next Assessment Date Window - End', dbMapping: 'metadata.nextAssessmentWindowDateEnd', fieldType: 'date' },
                { fieldLabel: 'Audit Type', dbMapping: 'metadata.generalAuditType.auditType',  fieldType: 'dropdown', dropdownOptions: ['ABVETEX', 'Better Work', 'Brand Audit', 'BSCI', 'Development audit', 'ERSA', 'ERSA-FLC', 'ERSA-FLC 3.0', 'Fair Trade', 'Higg VFSLM', 'SA8000', 'SMETA 2-Pillar', 'SMETA 4-Pillar', 'SQP', 'Verified SAQ (Elevate)', 'WCA', 'WRAP', 'No Assessment'] },
                { fieldLabel: 'Audit Firm', dbMapping: 'metadata.generalAuditType.auditFirm', fieldType: 'dropdown', dropdownOptions: ['Algi', 'Arche Advisors', 'Benchmarks Co. Ltd', 'Better Work', 'Bureau Veritas (BV)', 'CSR Solutions Limited', 'ELEVATE', 'ESTS', 'Intertek (ITS)', 'Labor Law', 'Omega', 'SGS', 'UL']},
                { fieldLabel: 'Confirmed Audit Date', dbMapping: 'metadata.confirmedAuditDate', fieldType: 'date' },
                { fieldLabel: 'Payer of Social Assessment', dbMapping: 'metadata.socialAssessmentInfo.socialAssessmentPayer', fieldType: 'dropdown', dropdownOptions: ['Reformation', 'Other-Brand', 'Other - Accommodation'] },
                { fieldLabel: 'Audit Range', dbMapping: 'metadata.auditRange', fieldType: 'dropdown', dropdownOptions: ['Annual', 'Bi-Annual', 'Exit', 'Follow-up', 'Full Initial', 'Partial Follow-up', 'Periodic', 'Quarterly LL', 'Re-Audit', 'Surveillance Audit'] },
                //{ fieldLabel: 'Whitespace', dbMapping: '', fieldType: 'none' },
                { fieldLabel: 'Whitespace', dbMapping: '', fieldType: 'none' },
                { fieldLabel: 'Social Responsibility Sign Off', dbMapping: 'metadata.socialResponsibilitySignOff', fieldType: 'bool' },
                { fieldLabel: 'Pending Social Compliance Info', dbMapping: 'metadata.pendingSocialComplianceInfo', fieldType: 'notes', gridSize: {sm: 9, md: 4.5} },
                { fieldLabel: 'Pending Documentation Follow Up - Social', dbMapping: 'metadata.pendingDocumentationFollowUpSocial', fieldType: 'date', gridSize: {sm: 9, md: 4.5}},
                { fieldLabel: 'Env. Responsibility Sign Off', dbMapping: 'metadata.environmentalResponsibilitySignOff', fieldType: 'bool' },
                { fieldLabel: 'Pending Environmental Compliance Info', dbMapping: 'metadata.pendingEnvironmentalComplianceInfo', fieldType: 'notes', gridSize: {sm: 9, md: 4.5} },
                { fieldLabel: 'Pending Documentation Follow Up - Environmental', dbMapping: 'metadata.pendingEnvironmentalFollowUpSocial', fieldType: 'date', gridSize: {sm: 9, md: 4.5} },
                { fieldLabel: 'Internal Status Notes', dbMapping: 'metadata.internalStatusNotes', fieldType: 'notes', gridSize: {sm: 12, md: 6} },
            ]
          },
          {
            name: 'Production Information',
            fields: [
                { fieldLabel: 'Facility Tier', dbMapping: 'metadata.tier', fieldType: 'dropdown', dropdownOptions: ['Tier Agent', 'Tier 1', 'Tier 2', 'Tier 3', 'Tier 4'] },
                { fieldLabel: 'Ref Product Category', dbMapping: 'metadata.productCategories', fieldType: 'dropdown multi', dropdownOptions: ['Apparel', 'Denim', 'Footwear', 'Sunglasses', 'Handbags', 'Sweaters', 'Tote Bags', 'Vintage', 'Masks', 'Private Label', 'The Collection', 'Accessories', 'Belts', 'Swim', 'Jewelry'] },
                { fieldLabel: 'Main Production processes at facility (i.e. cutting, sewing, finishing, etc.)', dbMapping: 'metadata.processInfo.productionProcesses', fieldType: 'dropdown multi', dropdownOptions: ['Agent', 'Assembly', 'bias', 'bias,binding spaghetti', 'binding spaghetti', 'Boiling', 'Bundling', 'Button hole and keyhole', 'Buttoning', 'Candle Making', 'Coating/Laminating', 'Combing', 'Scouring', 'Compostable Packaging', 'computer knitting', 'Converter', 'Cut & Sew', 'Cutting', 'Development', 'Dispatch', 'Distribution', 'Downfill', 'Dye House', 'Dyeing', 'Embroidery', 'Expedition', 'Farm', 'Fiber Producer', 'Fiber Trader', 'Final Product Assembly', 'Finishing', 'Fusing', 'Ginner', 'Glueing', 'Grading', 'Handwork', 'Hard Product Component & Trim Production', 'Headquarters', 'Heel Wrapper', 'Hot Stamping', 'Importer only', 'Ironing', 'Knitting', 'Knitting to Finishing', 'Laser', 'Lasting', 'Laundry', 'Linking', 'manufacturer', 'Marker', 'Mechanical Recycling', 'Mending', 'Mill', 'Molding', 'Nonwoven manufacturing', 'Other (enter)', 'Packaging', 'Packing', 'Packing, Finishing, Shipping', 'Painting', 'Pattern making', 'Placement Print', 'Pleating', 'Pre-fabrication', 'pre-shrink', 'Pre-Treatment', 'Pressing', 'Printer', 'Printing', 'Processor / Reeler', 'Product Cutting', 'QC', 'Repacking', 'Sampling', 'Sew', 'Sewing', 'Shipping', 'Slaughterhouse', 'Smocking', 'Spinning', 'Stamping', 'Stitching', 'Stone Wash', 'storage', 'Supplier', 'Surface treatment of metals', 'Tannery', 'Trading Company', 'Warehouse', 'Wash House', 'Washing', 'Weaving', 'Welt Pocket', 'Yarn Spinner'], gridSize: {sm: 12, md: 6} },
                { fieldLabel: 'Production Contract', dbMapping: 'metadata.processInfo.productionContractType', fieldType: 'dropdown multi', dropdownOptions: ['Domestic Contractor', 'Full Package', 'Private Label', 'Collab', 'CMT', 'Raw Material'] },
                { fieldLabel: 'Supply Chain Team', dbMapping: 'metadata.supplyChainTeam', fieldType: 'dropdown multi', dropdownOptions: ['Denim', 'Knits', 'Domestic', 'Import', 'Product Innovation', 'Vintage'] },
                { fieldLabel: 'Supply Chain Contact', dbMapping: 'metadata.supplyChainContact', fieldType: 'string' },
                { fieldLabel: 'Facility Affiliation', dbMapping: 'metadata.facilityAffiliation', fieldType: 'dropdown multi', dropdownOptions: ['Subcontractor', 'Joint Venture', 'Owned', 'Direct Partner'] },
                //{ fieldLabel: 'Whitespace', dbMapping: '', fieldType: 'none' },
                { fieldLabel: 'Who are your existing clients (brands/retailers)?', dbMapping: 'metadata.existingClients', fieldType: 'string', gridSize: {sm:12, md:6} },
                { fieldLabel: 'Peak Season', dbMapping: 'metadata.productionSeasonalityHigh', fieldType: 'dropdown multi', dropdownOptions: ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'August', 'Sept', 'Oct', 'Nov', 'Dec'] },
                { fieldLabel: 'Low Season', dbMapping: 'metadata.productionSeasonalityLow', fieldType: 'dropdown multi', dropdownOptions: ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'August', 'Sept', 'Oct', 'Nov', 'Dec'] },
                { fieldLabel: 'Minimum Order Quantity', dbMapping: 'metadata.minOrderQty', fieldType: 'string' },
                { fieldLabel: 'Average Lead time', dbMapping: 'metadata.avgLeadTime', fieldType: 'string' },
                { fieldLabel: 'Total Number of Machines', dbMapping: 'metadata.machineCount', fieldType: 'number' },
                { fieldLabel: 'Avg Units Produced/Week', dbMapping: 'metadata.avgUnitsProducedPerWeek', fieldType: 'number' },
                { fieldLabel: 'What is facility\'s annual average business volume with Ref (% of volume in meters, units, or kilograms)?', dbMapping: 'metadata.avgAnnualBusinessVolumeWithRefPercentage', fieldType: 'string', gridSize: {sm:12, md:7.5} },
                { fieldLabel: 'Average Annual Production Volume (in meters, units, or kilograms)?', dbMapping: 'metadata.avgAnnualBusinessVolume', fieldType: 'string', gridSize: {sm:12, md:4.5} },
            ]
        },
        {
            name: 'Social Compliance Information',
            fields: [
                { fieldLabel: 'Garment Registration / Business License #', dbMapping: 'metadata.businessLicenseInfo.licenseNo', fieldType: 'string' },
                { fieldLabel: 'Registration / License Expiration Date', dbMapping: 'metadata.businessLicenseInfo.licenseExpDate', fieldType: 'date' },
                { fieldLabel: 'Registration/License Upload', dbMapping: 'metadata.businessLicenseInfo.fileUpload', fieldType: 'file' },
                { fieldLabel: 'Whitespace', dbMapping: '', fieldType: 'none' },
                { fieldLabel: 'WCC Expiration Date (US only)', dbMapping: 'metadata.wcc.wccExpDate', fieldType: 'date' },
                { fieldLabel: 'WCC Expiration Date-International', dbMapping: 'metadata.wcc.wccExpDateInternational', fieldType: 'date' },
                { fieldLabel: 'Workers\' Comp Certificate (WCC)', dbMapping: 'metadata.wcc.fileUpload', fieldType: 'file' },
                { fieldLabel: 'Whitespace', dbMapping: '', fieldType: 'none' },
                { fieldLabel: 'NDA Signed?', dbMapping: 'metadata.nda.ndaCheck', fieldType: 'bool' },
                { fieldLabel: 'NDA Date Signed', dbMapping: 'metadata.nda.ndaSignedDate', fieldType: 'date' },
                { fieldLabel: 'NDA Signed Document', dbMapping: 'metadata.nda.fileUpload', fieldType: 'file' },
                { fieldLabel: 'Whitespace', dbMapping: '', fieldType: 'none' },
                { fieldLabel: 'SPG AOR 2022 Received', dbMapping: 'metadata.spgAor.spgAorJan2022Date', fieldType: 'date' },
                { fieldLabel: 'SPG Date Signed', dbMapping: 'metadata.spgAor.spgAorDate', fieldType: 'date' },
                { fieldLabel: 'SPG AOR', dbMapping: 'metadata.spgAor.fileUpload', fieldType: 'file' },
                { fieldLabel: 'Whitespace', dbMapping: '', fieldType: 'none' },
                { fieldLabel: 'Pre-Sourcing Facility Profile', dbMapping: 'metadata.miscFiles.presourcingFacilityProfileUpload', fieldType: 'file' },
                { fieldLabel: 'Exit Policy Checklist and Documentation', dbMapping: 'metadata.miscFiles.exitPolicyChecklistUpload', fieldType: 'file' },
                { fieldLabel: 'Date of Exit', dbMapping: 'metadata.miscDates.exitDate', fieldType: 'date' },
                { fieldLabel: 'Whitespace', dbMapping: '', fieldType: 'none' },
                { fieldLabel: 'Dormitories/Employee Housing Present?', dbMapping: 'metadata.housing.employeeHousingCheck', fieldType: 'dropdown', dropdownOptions: ['Yes', 'No'] },
                { fieldLabel: 'Do workers pay for housing?', dbMapping: 'metadata.housing.doWorkersPayForHousing', fieldType: 'dropdown', dropdownOptions: ['Yes', 'No'] },
                { fieldLabel: 'If so, how much?', dbMapping: 'metadata.housing.howMuchDoWorkersPayForHousing', fieldType: 'string' },
                { fieldLabel: 'Whitespace', dbMapping: '', fieldType: 'none' },
                { fieldLabel: 'Are there Multiple Tenants?', dbMapping: 'metadata.tenants.multipleTenantsCheck', fieldType: 'dropdown', dropdownOptions: ['Yes', 'No'] },
                { fieldLabel: 'If so, do they operate under the same business license?', dbMapping: 'metadata.tenants.multipleTenantsDoTheyOperateUnderSameBusinessLicense', fieldType: 'dropdown', dropdownOptions: ['Yes', 'No'], gridSize: {sm:12, md:6} },
                { fieldLabel: 'Whitespace', dbMapping: '', fieldType: 'none' },
                //{ fieldLabel: 'Whitespace', dbMapping: '', fieldType: 'none' },
                { fieldLabel: 'Does the facility actively* use the Higg FSLM to track and manage their social responsibility impact?', dbMapping: 'metadata.higg.higgFLSMCheck', fieldType: 'dropdown', dropdownOptions: ['Yes', 'No'], gridSize: {sm:12, md:6} },
                //{ fieldLabel: 'If yes to using the FSLM, please share your latest FSLM/FEM module with Reformation.', dbMapping: 'metadata.higg.higgFSLMPleaseShareModuleWithRef', fieldType: 'bool' },
                { fieldLabel: 'FSLM Certificate Upload', dbMapping: 'metadata.higg.fslmCertificateUpload', fieldType: 'file' },
                { fieldLabel: 'Whitespace', dbMapping: '', fieldType: 'none' },
                { fieldLabel: 'Current FSLM Score', dbMapping: 'metadata.higg.higgFSLMScore', fieldType: 'string' },
                { fieldLabel: 'Latest FSLM Module Completed', dbMapping: 'metadata.higg.higgFSLMModule', fieldType: 'string' },
                { fieldLabel: 'Whitespace', dbMapping: '', fieldType: 'none' },
                { fieldLabel: 'Whitespace', dbMapping: '', fieldType: 'none' },
                //{ fieldLabel: 'Whitespace', dbMapping: '', fieldType: 'none', gridSize: {sm:12, md:9} },
                { fieldLabel: '# of Social Assessments facility receives each year', dbMapping: 'metadata.socialAssessmentInfo.socialAssessmentCount', fieldType: 'number' },
                { fieldLabel: 'Types of Social Assessments', dbMapping: 'metadata.socialAssessmentInfo.socialAssessmentTypes', fieldType: 'dropdown multi', dropdownOptions: ['ABVETEX', 'Better Work', 'Brand Audit', 'BSCI', 'Development audit', 'ERSA', 'ERSA-FLC', 'ERSA-FLC 3.0', 'Fair Trade', 'Higg VFSLM', 'Labor Law Monitoring', 'SA8000', 'SMETA 2-Pillar', 'SMETA 4-Pillar', 'SQP', 'Verified SAQ (Elevate)', 'WCA', 'WRAP', 'No assessment'] },
                { fieldLabel: 'Whitespace', dbMapping: '', fieldType: 'none' },
                { fieldLabel: 'Have you adopted or assigned a code of conduct (CoC) and labor and environmental standards, or agreed to and signed a code of conduct of any of your buyers?', dbMapping: 'metadata.coc.cocCheck', fieldType: 'dropdown', dropdownOptions: ['Yes', 'No', 'Unsure'], gridSize: {sm:12, md:12} },
                { fieldLabel: 'If \'NO\', are you ready and willing to sign a code of conduct on workplace standards?', dbMapping: 'metadata.coc.ifNoCocWillingToSign', fieldType: 'dropdown', dropdownOptions: ['Yes', 'No'], gridSize: {sm:12, md:6} },
                { fieldLabel: 'What COC have you signed/aligned with?', dbMapping: 'metadata.coc.whichCOC', fieldType: 'dropdown multi', dropdownOptions: ['Fair Labor Association (FLA)', 'SA8000', 'Brand COC', 'Fair Trade', 'International COC', 'Other'], gridSize: {sm:12, md:6} },
                //{ fieldLabel: 'Whitespace', dbMapping: '', fieldType: 'none', gridSize: {sm:12, md:6} },
                { fieldLabel: 'Do all workers get paid at least the legal minimum wage?', dbMapping: 'metadata.facilityChecks.legalMinWage', fieldType: 'dropdown', dropdownOptions: ['Yes', 'No'],  gridSize: {sm:12, md:6} },
                { fieldLabel: 'Do you have a system to document the # of hours worked per day?', dbMapping: 'metadata.facilityChecks.systemToDocumentNumOfHoursWorkedPerDay', fieldType: 'dropdown', dropdownOptions: ['Yes', 'No'],  gridSize: {sm:12, md:6} },
                //{ fieldLabel: 'Whitespace', dbMapping: '', fieldType: 'none' },
                { fieldLabel: 'Time Recording System', dbMapping: 'metadata.timeRecordingSystem', fieldType: 'dropdown', dropdownOptions: ['Manual Time card', 'Biometric (ex. eye scan, fingerprint)', 'Electronic', 'Other'] },
                { fieldLabel: 'If entered other, please describe system', dbMapping: 'metadata.timeRecordingSystemOther', fieldType: 'string', gridSize: {sm:12, md:6} },
                { fieldLabel: 'Whitespace', dbMapping: '', fieldType: 'none' },
                { fieldLabel: 'Has the facility completed the FLA Wage Data Collection Tool?', dbMapping: 'metadata.fla.flaWageDataCollectionCheck', fieldType: 'dropdown', dropdownOptions: ['Yes', 'No', 'Never received tool'], gridSize: {sm:12, md:6} },
                { fieldLabel: 'FLA Wage Tool', dbMapping: 'metadata.fla.flaWageTool', fieldType: 'file' },
                { fieldLabel: 'Reviewed by Ref?', dbMapping: 'metadata.fla.flaWageReviewDate', fieldType: 'date' },
                //{ fieldLabel: 'Whitespace', dbMapping: '', fieldType: 'none' },
                { fieldLabel: 'Do you or have you worked with any FLA Accredited brands?', dbMapping: 'metadata.fla.flaCheck', fieldType: 'dropdown', dropdownOptions: ['Yes', 'No'], gridSize: {sm:12, md:6} },
                { fieldLabel: 'If \'Yes\', please specify which brands', dbMapping: 'metadata.fla.flaBrands', fieldType: 'string', gridSize: {sm:12, md:6} },
            ]
        },
        {
            name: 'Employee Information',
            fields: [
              { fieldLabel: 'Total Number of Employees', dbMapping: 'metadata.workerCountTotal', fieldType: 'number' },
              { fieldLabel: 'Total Number of Production Employees', dbMapping: 'metadata.workerCountProduction', fieldType: 'number' },
              { fieldLabel: 'Whitespace', dbMapping: '', fieldType: 'none' },
              { fieldLabel: 'Whitespace', dbMapping: '', fieldType: 'none' },
              { fieldLabel: 'Number of Male workers', dbMapping: 'metadata.workerGenderMale', fieldType: 'number' },
              { fieldLabel: 'Number of Female workers', dbMapping: 'metadata.workerGenderFemale', fieldType: 'number' },
              { fieldLabel: 'Number of Local/ Domestic Migrant Workers', dbMapping: 'metadata.domesticMigrantWorkers', fieldType: 'number' },
              { fieldLabel: 'Number of Foreign Migrant Workers', dbMapping: 'metadata.foreignMigrantWorkers', fieldType: 'number' },
              { fieldLabel: 'Are workers hired through labor brokers/staffing agencies?', dbMapping: 'metadata.employeeInfo.workedHiredThroughBrokersOrAgenciesCheck', fieldType: 'dropdown', dropdownOptions: ['Yes', 'No'], gridSize: {sm:9,md:4.5} },
              { fieldLabel: 'Do workers pay fees to the brokers/staffing agencies?', dbMapping: 'metadata.employeeInfo.agencyWorkersFeesPaidCheck', fieldType: 'dropdown', dropdownOptions: ['Yes', 'No'], gridSize: {sm:9,md:4.5} },
              { fieldLabel: 'Do employees retain their own documents?', dbMapping: 'metadata.employeeInfo.doEmployeesRetainTheirDocuments', fieldType: 'dropdown', dropdownOptions: ['Yes', 'No', 'Unsure'], gridSize: {sm:9,md:4.5} },
              { fieldLabel: 'If \'NO\', who retains documents and where are they maintained?', dbMapping: 'metadata.employeeInfo.whoRetainsEmployeeDocuments', fieldType: 'string', gridSize: {sm:9,md:4.5} },
              { fieldLabel: 'Whitespace', dbMapping: '', fieldType: 'none' },
              { fieldLabel: 'Age of youngest worker at facility', dbMapping: 'metadata.employeeInfo.ageOfYoungestWorker', fieldType: 'number' },
            ]
          },
          {
            name: 'Freedom of Association',
            fields: [
              { fieldLabel: 'Is there a union in the facility?', dbMapping: 'metadata.freedomOfAssoc.unionCheck', fieldType: 'dropdown', dropdownOptions: ['Yes', 'No'], gridSize: {sm:9,md:4.5} },
              { fieldLabel: 'If \'YES\', name of union', dbMapping: 'metadata.freedomOfAssoc.unionName', fieldType: 'string', gridSize: {sm:9,md:4.5} },
              { fieldLabel: 'Whitespace', dbMapping: '', fieldType: 'none' },
              { fieldLabel: 'Do employees pay union dues?', dbMapping: 'metadata.freedomOfAssoc.employeesPayUnionDuesCheck', fieldType: 'dropdown', dropdownOptions: ['Yes', 'No', 'Optional', 'None of the above'], gridSize: {sm:9,md:4.5} },
              { fieldLabel: 'If \'YES\', how much & how often are dues deducted?', dbMapping: 'metadata.freedomOfAssoc.howMuchHowOftenDues', fieldType: 'string', gridSize: {sm:9,md:4.5} },
              { fieldLabel: 'Whitespace', dbMapping: '', fieldType: 'none' },
              { fieldLabel: 'Is there a Collective Bargaining Agreement (CBA) in place?', dbMapping: 'metadata.freedomOfAssoc.cbaCheck', fieldType: 'dropdown', dropdownOptions: ['Yes', 'No'], gridSize: {sm:9,md:4.5} },
              { fieldLabel: 'If \'YES\', CBA valid period', dbMapping: 'metadata.freedomOfAssoc.cbaPeriod', fieldType: 'string', gridSize: {sm:9,md:4.5} },
              { fieldLabel: 'Whitespace', dbMapping: '', fieldType: 'none' },
              { fieldLabel: 'Is there a worker Committee in place?', dbMapping: 'metadata.workerCommitteeCheck', fieldType: 'dropdown', dropdownOptions: ['Yes', 'No'], gridSize: {sm:9,md:4.5} },
            ]
          },
          {
            name: 'Subcontractor Information',
            fields: [
              { fieldLabel: 'Does the Facility employ subcontractors for any part of the production process?', dbMapping: 'metadata.subcontractors.subcontractorsCheck', fieldType: 'dropdown', dropdownOptions: ['Yes', 'No', 'N/A'], gridSize: { sm: 9, md: 6 } },
              { fieldLabel: 'If \'Yes\', provide details', dbMapping: 'metadata.subcontractors.details', fieldType: 'string' },
              // { fieldLabel: 'Subcontractor Information', dbMapping: 'metadata.subcontractors.subcontractorInfo', fieldType: 'dropdown', dropdownOptions: ['Yes', 'No'] },
            ]
          },
          {
            name: 'Environmental Management',
            fields: [
                { fieldLabel: 'Do you have a comprehensive environmental management system in place?', dbMapping: 'metadata.envMgmt.envMgmtSystemCheck', fieldType: 'dropdown', dropdownOptions: ['Yes', 'No', 'N/A'], gridSize: { sm: 12, md: 6 } },
                { fieldLabel: 'Is your facility in compliance with all federal, state, and local environmental permitting requirements?', dbMapping: 'metadata.envMgmt.envPermitComplianceCheck', fieldType: 'dropdown', dropdownOptions: ['Yes', 'No', 'N/A'], gridSize: { sm: 12, md: 6 } },
                { fieldLabel: 'Wastewater Discharge Permit', dbMapping: 'metadata.envMgmt.wastewaterDischargePermitCheck', fieldType: 'dropdown', dropdownOptions: ['Yes', 'No', 'Unknown'], gridSize: { sm: 9, md: 4.5 } },
                { fieldLabel: 'Wastewater Discharge Permit Exp', dbMapping: 'metadata.envMgmt.wastewaterDischargePermitExpDate', fieldType: 'date', gridSize: { sm: 9, md: 4.5 } },
                { fieldLabel: 'Wastewater Discharge Permit Upload', dbMapping: 'metadata.envMgmt.wastewaterDischargePermitUpload', fieldType: 'file' },
                //{ fieldLabel: 'Whitespace', dbMapping: '', fieldType: 'none' },
                { fieldLabel: 'Is wastewater discharge from the facility tested by a third party laboratory on a regular basis?', dbMapping: 'metadata.envMgmt.wastewaterDischargeTestedCheck', fieldType: 'dropdown', dropdownOptions: ['Yes', 'Partial Yes', 'No', 'Unknown', 'Not Applicable'], gridSize: { sm: 12, md: 9 } },
                { fieldLabel: 'Whitespace', dbMapping: '', fieldType: 'none' },
                { fieldLabel: 'Stormwater Discharge Permit', dbMapping: 'metadata.envMgmt.stormwaterDischargePermitCheck', fieldType: 'dropdown', dropdownOptions: ['Yes', 'No', 'Unknown'], gridSize: { sm: 9, md: 4.5 } },
                { fieldLabel: 'Stormwater Discharge Permit Exp', dbMapping: 'metadata.envMgmt.stormwaterDischargePermitExpDate', fieldType: 'date', gridSize: { sm: 9, md: 4.5 } },
                { fieldLabel: 'Stormwater Discharge Permit Upload', dbMapping: 'metadata.envMgmt.stormwaterDischargePermitUpload', fieldType: 'file' },
                //{ fieldLabel: 'Whitespace', dbMapping: '', fieldType: 'none' },
                { fieldLabel: 'Waste Generation and Handling Permit', dbMapping: 'metadata.envMgmt.wasteGenerationHandlingPermitCheck', fieldType: 'dropdown', dropdownOptions: ['Yes', 'No', 'Unknown', 'N/A'], gridSize: { sm: 9, md: 4.5 } },
                { fieldLabel: 'Waste Generation and Handling Permit Exp', dbMapping: 'metadata.envMgmt.wasteGenerationHandlingPermitExpDate', fieldType: 'date', gridSize: { sm: 9, md: 4.5 } },
                { fieldLabel: 'Waste Generation and Handling Permit Upload', dbMapping: 'metadata.envMgmt.wasteGenerationHandlingPermitUpload', fieldType: 'file' },
                //{ fieldLabel: 'Whitespace', dbMapping: '', fieldType: 'none' },
                { fieldLabel: 'Air Permit', dbMapping: 'metadata.envMgmt.airPermitCheck', fieldType: 'dropdown', dropdownOptions: ['Yes', 'No', 'Unknown'], gridSize: { sm: 9, md: 4.5 } },
                { fieldLabel: 'Air Permit Exp', dbMapping: 'metadata.envMgmt.airPermitExpDate', fieldType: 'date', gridSize: { sm: 9, md: 4.5 } },
                { fieldLabel: 'Air Permit Upload', dbMapping: 'metadata.envMgmt.airPermitUpload', fieldType: 'file' },
            ]
        },
        {
            name: 'Environmental Compliance',
            fields: [
                { fieldLabel: 'Do you have a program/system to review and monitor environmental permit status and renewal?', dbMapping: 'metadata.envCompliance.envPermitStatusProgramCheck', fieldType: 'dropdown', dropdownOptions: ['Yes', 'No'], gridSize: { sm: 9, md: 7.5 } },
                { fieldLabel: 'If \'Yes\', please describe the process & provide SOP (if available)', dbMapping: 'metadata.envCompliance.envPermitStatusSOPUpload', fieldType: 'file', gridSize: { sm: 9, md: 4.5 } },
                { fieldLabel: 'Does your facility have a means to stay up-to-date with legal requirements regarding environmental compliance (i.e. emissions, water, energy, chemicals, etc.)?', dbMapping: 'metadata.envCompliance.envComplianceLegalRequirementsMeansCheck', fieldType: 'dropdown', dropdownOptions: ['Yes', 'No'], gridSize: { sm: 9, md: 7.5 } },
                { fieldLabel: 'If \'Yes\', describe the process & provide SOP (if available)', dbMapping: 'metadata.envCompliance.envComplianceLegalRequirementsSOPUpload', fieldType: 'file', gridSize: { sm: 9, md: 4.5 } },
                { fieldLabel: 'Do you have a stormwater management plan?', dbMapping: 'metadata.envCompliance.stormwaterMgmtPlanCheck', fieldType: 'dropdown', dropdownOptions: ['Yes', 'No', 'N/A'], gridSize: { sm: 12, md: 6 } },
                { fieldLabel: 'Whitespace', dbMapping: '', fieldType: 'none' },
                { fieldLabel: 'Whitespace', dbMapping: '', fieldType: 'none' },
                { fieldLabel: 'Do you have an onsite wastewater treatment system?', dbMapping: 'metadata.envCompliance.wastewaterTreatmentSystemOnsiteCheck', fieldType: 'dropdown', dropdownOptions: ['Yes', 'No', 'N/A', 'Indirect emissions'], gridSize: { sm: 12, md: 6 } },
                { fieldLabel: 'Wastewater Treatment Type', dbMapping: 'metadata.envCompliance.wastewaterTreatmentType', fieldType: 'dropdown', dropdownOptions: ['Full Treatment', 'Municipal/Local Treatment only', 'Pretreatment only', 'None'], gridSize: { sm: 12, md: 6 } },
                //{ fieldLabel: 'Whitespace', dbMapping: '', fieldType: 'none' },
                { fieldLabel: 'Do you have a chemical management plan?', dbMapping: 'metadata.envCompliance.chemicalMgmtPlanCheck', fieldType: 'dropdown', dropdownOptions: ['Yes', 'No', 'N/A'], gridSize: { sm: 12, md: 6 } },
                { fieldLabel: 'Whitespace', dbMapping: '', fieldType: 'none' },
                { fieldLabel: 'Whitespace', dbMapping: '', fieldType: 'none' },
                { fieldLabel: 'Do you have an emergency spill response plan?', dbMapping: 'metadata.envCompliance.emergencySpillResponsePlanCheck', fieldType: 'dropdown', dropdownOptions: ['Yes', 'No', 'N/A'], gridSize: { sm: 12, md: 6 } },
                { fieldLabel: 'Whitespace', dbMapping: '', fieldType: 'none' },
                { fieldLabel: 'Whitespace', dbMapping: '', fieldType: 'none' },
                { fieldLabel: 'Is a Chemical Inventory List maintained for all production & operational chemicals used onsite?', dbMapping: 'metadata.envCompliance.chemicalInventoryList', fieldType: 'dropdown', dropdownOptions: ['Yes', 'Partial Yes: Production Chemicals Only', 'Partial Yes: Operational Chemicals Only', 'Partial Yes: In Process of Developing Inventory', 'No'], gridSize: { sm: 12, md: 9 } },
                { fieldLabel: 'If \'Yes\', please provide the latest inventory report', dbMapping: 'metadata.envCompliance.chemicalInventoryListUpload', fieldType: 'file' },
                { fieldLabel: 'Is the chemical inventory list compliant with the facility Manufacturer Restricted Substance List (MRSL)?', dbMapping: 'metadata.envCompliance.chemicalInventoryListCompliance', fieldType: 'dropdown', dropdownOptions: ['Yes', 'No', 'Unknown', 'Partial Yes', 'None of the above'], gridSize: { sm: 12, md: 6 } },
                { fieldLabel: 'How often is the compliance of the chemical inventory internally verified?', dbMapping: 'metadata.envCompliance.chemicalInventoryInternalVerificationFrequency', fieldType: 'dropdown', dropdownOptions: ['Daily', 'Weekly', 'Monthly', 'Quarterly', 'Annually', 'Not verified on a regular basis', 'None of the above'], gridSize: { sm: 12, md: 6 } },
                { fieldLabel: 'Chemical Inventory Compliance Type', dbMapping: 'metadata.envCompliance.chemicalInventoryComplianceType', fieldType: 'dropdown multi', dropdownOptions: ['ZDHC Level 1', 'ZDHC Level 2', 'ZDHC Level 3', 'Bluesign', 'Oeko-text', 'GOTS', 'GRS', 'None'], gridSize: { sm: 12, md: 6 } },
                { fieldLabel: 'Whitespace', dbMapping: '', fieldType: 'none' },
                { fieldLabel: 'Whitespace', dbMapping: '', fieldType: 'none' },
                { fieldLabel: 'Does the facility actively* use the Higg FEM to track and manage their environmental impact?', dbMapping: 'metadata.higg.higgFEMCheck', fieldType: 'dropdown', dropdownOptions: ['Yes', 'No'], gridSize: { sm: 12, md: 6 }},
                //{ fieldLabel: 'If yes to using the FEM, please share your latest FEM module with Reformation.', dbMapping: 'metadata.higg.higgFEMPleaseShareModule', fieldType: 'bool', gridSize: { sm: 12, md: 6 } },
                { fieldLabel: 'Current Higg FEM Total Score', dbMapping: 'metadata.higg.higgFEMScore', fieldType: 'string' },
                { fieldLabel: 'Latest Higg FEM Module Completed', dbMapping: 'metadata.higg.higgFEMModule', fieldType: 'string' }, // dropdownOptions: ['FEM 2019', 'FEM 2020', 'FEM 2021', 'FEM 2022', 'Not Shared in Portal']
                { fieldLabel: 'Whitespace', dbMapping: '', fieldType: 'none' },
                { fieldLabel: 'Has the facility actively participated in any resource effiency or clean chemistry programs in the last two years? (i.e., Apparel Impact Institute\'s Clean by Design and Carbon Leadership Program, Bluesign, ZDHC\'s Roadmap to Zero, etc.)', dbMapping: 'metadata.envCompliance.resourceEfficiencyProgramCheck', fieldType: 'dropdown', dropdownOptions: ['Yes', 'No'], gridSize: { sm: 12, md: 12 } },
                // { fieldLabel: 'Whitespace', dbMapping: '', fieldType: 'none' },
                { fieldLabel: 'If \'YES\', please list the programs', dbMapping: 'metadata.envCompliance.resourceEfficiencyProgramList', fieldType: 'notes', gridSize: { sm: 12, md: 6 } },
                { fieldLabel: 'If yes, please briefly describe the program(s) and when it occurred', dbMapping: 'metadata.envCompliance.resourceEfficiencyProgramDescription', fieldType: 'notes', gridSize: { sm: 12, md: 6 } },
                { fieldLabel: 'Sustainability Program Engagement', dbMapping: 'metadata.envCompliance.sustainabilityProgramEngagement', fieldType: 'dropdown multi', dropdownOptions: ['Aii CbD', 'Aii CLP', 'SP RETP', 'ZDHC R2Z', 'Oeko-tex STEP'], gridSize: { sm: 12, md: 6 } },
                { fieldLabel: 'Sustainability Program Engagement - Year(s) Engaged', dbMapping: 'metadata.envCompliance.sustainabilityProgramEngagementYears', fieldType: 'dropdown multi', dropdownOptions: ['2018', '2019', '2020', '2021', '2022', '2023', '2024', '2025', '2026'], gridSize: { sm: 12, md: 6 } },
            ]
          },
          {
            name: 'Current Certifications/Projects',
            fields: [
                { fieldLabel: 'BSCI', dbMapping: 'metadata.certifications.bsciCheck', fieldType: 'dropdown', dropdownOptions: ['Yes', 'No', 'None of the above'] },
                { fieldLabel: 'BSCI Expiration Date', dbMapping: 'metadata.certifications.bsciExpDate', fieldType: 'date', gridSize: { sm: 9, md: 4 } },
                { fieldLabel: 'BSCI Certificate', dbMapping: 'metadata.certifications.bsciUpload', fieldType: 'file' },
                //{ fieldLabel: 'Whitespace', dbMapping: '', fieldType: 'none' },
                { fieldLabel: 'ICTI', dbMapping: 'metadata.certifications.ictiCheck', fieldType: 'dropdown', dropdownOptions: ['Yes', 'No', 'None of the above'] },
                { fieldLabel: 'ICTI Expiration Date', dbMapping: 'metadata.certifications.ictiExpDate', fieldType: 'date', gridSize: { sm: 9, md: 4 } },
                { fieldLabel: 'ICTI Certificate', dbMapping: 'metadata.certifications.ictiUpload', fieldType: 'file' },
                //{ fieldLabel: 'Whitespace', dbMapping: '', fieldType: 'none' },
                { fieldLabel: 'WRAP', dbMapping: 'metadata.certifications.wrapCheck', fieldType: 'dropdown', dropdownOptions: ['Yes', 'No', 'None of the above'] },
                { fieldLabel: 'WRAP Expiration Date', dbMapping: 'metadata.certifications.wrapExpDate', fieldType: 'date', gridSize: { sm: 9, md: 4 } },
                { fieldLabel: 'WRAP Certificate', dbMapping: 'metadata.certifications.wrapUpload', fieldType: 'file' },
                //{ fieldLabel: 'Whitespace', dbMapping: '', fieldType: 'none' },
                { fieldLabel: 'SA8000', dbMapping: 'metadata.certifications.sa8000Check', fieldType: 'dropdown', dropdownOptions: ['Yes', 'No', 'None of the above'] },
                { fieldLabel: 'SA8000 Expiration Date', dbMapping: 'metadata.certifications.sa8000ExpDate', fieldType: 'date', gridSize: { sm: 9, md: 4 } },
                { fieldLabel: 'SA8000 Certificate', dbMapping: 'metadata.certifications.sa8000Upload', fieldType: 'file' },
                //{ fieldLabel: 'Whitespace', dbMapping: '', fieldType: 'none' },
                { fieldLabel: 'SMETA', dbMapping: 'metadata.certifications.smetaCheck', fieldType: 'dropdown', dropdownOptions: ['Yes', 'No', 'None of the above'] },
                { fieldLabel: 'SMETA Expiration Date', dbMapping: 'metadata.certifications.smetaExpDate', fieldType: 'date', gridSize: { sm: 9, md: 4 } },
                { fieldLabel: 'SMETA Certificate', dbMapping: 'metadata.certifications.smetaUpload', fieldType: 'file' },
                //{ fieldLabel: 'Whitespace', dbMapping: '', fieldType: 'none' },
                { fieldLabel: 'Better Work', dbMapping: 'metadata.certifications.betterWorkCheck', fieldType: 'dropdown', dropdownOptions: ['Yes', 'No', 'None of the above'] },
                { fieldLabel: 'Better Work Expiration Date', dbMapping: 'metadata.certifications.betterWorkExpDate', fieldType: 'date', gridSize: { sm: 9, md: 4 } },
                { fieldLabel: 'Better Work Certificate', dbMapping: 'metadata.certifications.betterWorkUpload', fieldType: 'file' },
                //{ fieldLabel: 'Whitespace', dbMapping: '', fieldType: 'none' },
                { fieldLabel: 'BAP', dbMapping: 'metadata.certifications.bapCheck', fieldType: 'dropdown', dropdownOptions: ['Yes', 'No', 'None of the above'] },
                { fieldLabel: 'BAP Expiration Date', dbMapping: 'metadata.certifications.bapExpDate', fieldType: 'date', gridSize: { sm: 9, md: 4 } },
                { fieldLabel: 'BAP Certificate', dbMapping: 'metadata.certifications.bapUpload', fieldType: 'file' },
                //{ fieldLabel: 'Whitespace', dbMapping: '', fieldType: 'none' },
                { fieldLabel: 'ISO 9001', dbMapping: 'metadata.certifications.iso9001Check', fieldType: 'dropdown', dropdownOptions: ['Yes', 'No', 'None of the above'] },
                { fieldLabel: 'ISO 9001 Expiration Date', dbMapping: 'metadata.certifications.iso9001ExpDate', fieldType: 'date', gridSize: { sm: 9, md: 4 } },
                { fieldLabel: 'ISO 9001 Certificate', dbMapping: 'metadata.certifications.iso9001Upload', fieldType: 'file' },
                //{ fieldLabel: 'Whitespace', dbMapping: '', fieldType: 'none' },
                { fieldLabel: 'ISO 14001', dbMapping: 'metadata.certifications.iso14001Check', fieldType: 'dropdown', dropdownOptions: ['Yes', 'No', 'None of the above'] },
                { fieldLabel: 'ISO 14001 Expiration Date', dbMapping: 'metadata.certifications.iso14001ExpDate', fieldType: 'date', gridSize: { sm: 9, md: 4 } },
                { fieldLabel: 'ISO 14001 Certificate', dbMapping: 'metadata.certifications.iso14001Upload', fieldType: 'file' },
                //{ fieldLabel: 'Whitespace', dbMapping: '', fieldType: 'none' },
                { fieldLabel: 'ISO 18001', dbMapping: 'metadata.certifications.iso18001Check', fieldType: 'dropdown', dropdownOptions: ['Yes', 'No', 'None of the above'] },
                { fieldLabel: 'ISO 18001 Expiration Date', dbMapping: 'metadata.certifications.iso18001ExpDate', fieldType: 'date', gridSize: { sm: 9, md: 4 } },
                { fieldLabel: 'ISO 18001 Certificate', dbMapping: 'metadata.certifications.iso18001Upload', fieldType: 'file' },
                //{ fieldLabel: 'Whitespace', dbMapping: '', fieldType: 'none' },
                { fieldLabel: 'BLUESIGN® system', dbMapping: 'metadata.certifications.bluesignCheck', fieldType: 'dropdown', dropdownOptions: ['Yes', 'No', 'None of the above'] },
                { fieldLabel: 'BLUESIGN® system Expiration Date', dbMapping: 'metadata.certifications.bluesignExpDate', fieldType: 'date', gridSize: { sm: 9, md: 4 } },
                { fieldLabel: 'BLUESIGN® system Certificate', dbMapping: 'metadata.certifications.bluesignUpload', fieldType: 'file' },
                //{ fieldLabel: 'Whitespace', dbMapping: '', fieldType: 'none' },
                { fieldLabel: 'OEKO-TEX® Standard 100', dbMapping: 'metadata.certifications.okeotexStandard100Check', fieldType: 'dropdown', dropdownOptions: ['Yes', 'No', 'None of the above'] },
                { fieldLabel: 'OEKO-TEX® Standard 100 Expiration Date', dbMapping: 'metadata.certifications.okeotexStandard100ExpDate', fieldType: 'date', gridSize: { sm: 9, md: 4 } },
                { fieldLabel: 'OEKO-TEX® Standard 100 Certificate', dbMapping: 'metadata.certifications.okeotexStandard100Upload', fieldType: 'file' },
                //{ fieldLabel: 'Whitespace', dbMapping: '', fieldType: 'none' },
                { fieldLabel: 'OEKO-TEX® Made In Green', dbMapping: 'metadata.certifications.okeotexMadeInGreenCheck', fieldType: 'dropdown', dropdownOptions: ['Yes', 'No', 'None of the above'] },
                { fieldLabel: 'OEKO-TEX® Made In Green Expiration Date', dbMapping: 'metadata.certifications.okeotexMadeInGreenExpDate', fieldType: 'date', gridSize: { sm: 9, md: 4 } },
                { fieldLabel: 'OEKO-TEX® Made In Green Certificate', dbMapping: 'metadata.certifications.okeotexMadeInGreenUpload', fieldType: 'file' },
                //{ fieldLabel: 'Whitespace', dbMapping: '', fieldType: 'none' },
                { fieldLabel: 'OEKO-TEX® Detox to Zero', dbMapping: 'metadata.certifications.okeotexDetoxToZeroCheck', fieldType: 'dropdown', dropdownOptions: ['Yes', 'No', 'None of the above'] },
                { fieldLabel: 'OEKO-TEX® Detox to Zero Expiration Date', dbMapping: 'metadata.certifications.okeotexDetoxToZeroExpDate', fieldType: 'date', gridSize: { sm: 9, md: 4 } },
                { fieldLabel: 'OEKO-TEX® Detox to Zero Certificate', dbMapping: 'metadata.certifications.okeotexDetoxToZeroUpload', fieldType: 'file' },
                //{ fieldLabel: 'Whitespace', dbMapping: '', fieldType: 'none' },
                { fieldLabel: 'OEKO-TEX® STeP', dbMapping: 'metadata.certifications.okeotexStepCheck', fieldType: 'dropdown', dropdownOptions: ['Yes', 'No', 'None of the above'] },
                { fieldLabel: 'OEKO-TEX® STeP Expiration Date', dbMapping: 'metadata.certifications.okeotexStepExpDate', fieldType: 'date', gridSize: { sm: 9, md: 4 } },
                { fieldLabel: 'OEKO-TEX® STeP Certificate', dbMapping: 'metadata.certifications.okeotexStepUpload', fieldType: 'file' },
                //{ fieldLabel: 'Whitespace', dbMapping: '', fieldType: 'none' },
                { fieldLabel: 'OEKO-TEX® Eco-Passport', dbMapping: 'metadata.certifications.okeotexEcoPassportCheck', fieldType: 'dropdown', dropdownOptions: ['Yes', 'No', 'None of the above'] },
                { fieldLabel: 'OEKO-TEX® Eco-Passport Expiration Date', dbMapping: 'metadata.certifications.okeotexEcoPassportExpDate', fieldType: 'date', gridSize: { sm: 9, md: 4 } },
                { fieldLabel: 'OEKO-TEX® Eco-Passport Certificate', dbMapping: 'metadata.certifications.okeotexEcoPassportUpload', fieldType: 'file' },
                //{ fieldLabel: 'Whitespace', dbMapping: '', fieldType: 'none' },
                { fieldLabel: 'ZDHC In-Check Report', dbMapping: 'metadata.certifications.zdhcReportCheck', fieldType: 'dropdown', dropdownOptions: ['Yes', 'No', 'None of the above'] },
                { fieldLabel: 'ZDHC In-Check Report Expiration Date', dbMapping: 'metadata.certifications.zdhcReportExpDate', fieldType: 'date', gridSize: { sm: 9, md: 4 } },
                { fieldLabel: 'ZDHC In-Check Report File', dbMapping: 'metadata.certifications.zdhcReportUpload', fieldType: 'file' },
                //{ fieldLabel: 'Whitespace', dbMapping: '', fieldType: 'none' },
                { fieldLabel: 'Other', dbMapping: 'metadata.certifications.otherCertCheck', fieldType: 'dropdown', dropdownOptions: ['Yes', 'No'] },
                { fieldLabel: 'Other Expiration Date', dbMapping: 'metadata.certifications.otherCertExpDate', fieldType: 'date', gridSize: { sm: 9, md: 4 } },
                { fieldLabel: 'Other Certificate', dbMapping: 'metadata.certifications.otherCertUpload', fieldType: 'file' },
                { fieldLabel: 'Other (please specify name/details)', dbMapping: 'metadata.certifications.otherCertList', fieldType: 'string' },
            ]
        },
    ]

    return (
        <Box sx={{ position: 'relative', height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                <Button
                    onClick={handleSubmit}
                    variant="contained"
                    startIcon={<SaveIcon />}
                    sx={{
                        backgroundColor: '#585aeb',
                        '&:hover': {
                            backgroundColor: '#4648c4',
                        },
                    }}
                >
                    Save Changes
                </Button>
            </Box>
            <Box sx={{ flexGrow: 1, overflowY: 'auto', p: 2, height: 'calc(100vh - 60px)' }}>
                {sections.map((section) => renderSection(section.name, section.fields))}
            </Box>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
                message={snackbarMessage}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert 
                    onClose={() => setSnackbarOpen(false)} 
                    severity={snackbarSeverity}
                    sx={{ width: '100%' }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default FacilityDataTab;