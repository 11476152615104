import React, { useEffect, useState, useRef } from "react";
import { Grid, Typography, Box, Divider, Card, Switch } from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectSupplierNameFromId } from "../selectors/supplier.selector";

import TopBreadcrumbs from "../components/topBreadcrumbs.component";
import LeftSideNavbar from "../components/leftSideNavbar.component";
import {
  selectFacilityPageTab,
  selectFactoryData,
  selectFactoryNameFromId,
  selectFactoryTierFromId,
} from "../selectors/factory.selector";
import FacilityInformation from "../components/FacilityDetails/facilityInformation.component";
import FacilityInternalInformation from "../components/FacilityDetails/facilityInternalInformation.component";
import FacilityTabs from "../components/FacilityDetails/facilityTabs.component";
import FacilityComplianceStatus from "../components/FacilityDetails/facilityComplianceStatus.component";
import FacilityDataTab from "../components/FacilityDetails/facilityDataTab.component";
import FacilityAuditList from "../components/FacilityDetails/facilityAuditList.component";
import FacilityInformationDrawer from "../components/FacilityDetails/facilityInformationDrawer.component";
import { getFactoryById } from "../reducers/factory.reducer";
import EditToggleSwitch from "../components/editToggleSwitch.component";
import ConfidentialBusinessInformation from "../components/confidentialBusinessInformation.component";
import AdditionalFactoryInformation from "../components/FacilityDetails/facilityAdditionalInformation.component";
import { PageStyles } from "../styles";
import {
  selectAuditList,
  selectAuditListForFactory,
  selectMostRecentAuditIdForFactory,
} from "../selectors/audit.selector";
import { getListOfAllAudits, getAuditById } from "../reducers/audit.reducer";

const FacilityDetails = () => {
  const generalInfoRef = useRef(null);
  const params = useParams();
  const dispatch = useDispatch();

  const selectedFactoryData = useSelector(selectFactoryData);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchFactoryData = async () => {
      if (!selectedFactoryData || selectedFactoryData.id !== params.factoryId) {
        setIsLoading(true);
        try {
          await dispatch(
            getFactoryById({
              supplierId: params.supplierId,
              factoryId: params.factoryId,
            })
          );
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchFactoryData();
  }, [params.factoryId, params.supplierId, dispatch]);

  const selectedTab = useSelector(selectFacilityPageTab);

  useEffect(() => {
    dispatch(
      getListOfAllAudits({
        limit: 99,
        offset: 0,
        supplierId: params.supplierId,
      })
    );
  }, []);

  return (
    <Box sx={PageStyles.containerBox}>
      <LeftSideNavbar />
      <Box sx={PageStyles.containerBoxExceptNavbar}>
        <Box sx={PageStyles.firstSectionWithBreadcrumbsAndName}>
          <TopBreadcrumbs />
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography gutterBottom sx={PageStyles.name}>
                {selectedFactoryData?.name}
                {selectedFactoryData?.metadata?.tier && (
                  <Box
                    sx={PageStyles.tierAndPrivateLabel}
                    size="small"
                    variant="outlined"
                  >
                    Tier {selectedFactoryData?.metadata?.tier}
                  </Box>
                )}
                {selectedFactoryData?.metadata?.privateLabel === "Yes" && (
                  <Box
                    sx={PageStyles.tierAndPrivateLabel}
                    size="small"
                    variant="outlined"
                  >
                    Private Label
                  </Box>
                )}
              </Typography>
            </Grid>
            {/* <Grid item>
              <EditToggleSwitch />
            </Grid> */}
          </Grid>
        </Box>
        <Divider />

        <Grid container spacing={2} sx={PageStyles.secondSectionGrid}>
          <Grid item xs={12} md={8}>
            <div ref={generalInfoRef}>
              <FacilityInformation />
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <ConfidentialBusinessInformation
              title="Internal Status Notes"
              data={selectedFactoryData}
            />
          </Grid>
          <Grid item xs={12}>
            <AdditionalFactoryInformation />
          </Grid>
          <Grid item xs={12}>
            <Card sx={PageStyles.cardForTabsAndBelow}>
              <Grid container>
                <Grid item xs={12}>
                  <FacilityTabs />
                </Grid>
                <Grid item xs={12}>
                  {selectedTab === "status" ? (
                    <FacilityComplianceStatus />
                  ) : selectedTab === "audits" ? (
                    <FacilityAuditList />
                  ) : (
                    <FacilityDataTab/>
                  )}
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default FacilityDetails;