import React from "react";
import { Box, Grid, Typography, Drawer, Divider, Checkbox } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { selectSupplierNameFromId } from "../../selectors/supplier.selector";
import { useSelector } from "react-redux";
import { ComponentStyles, FacilityStyles } from "../../styles";
import FacilityTierMap from "./facilityTierMap.component";

const FacilityInformationDrawer = ({
  factoryData,
  isDrawerOpen,
  toggleDrawer,
}) => {
  const params = useParams();
  const supplierName = useSelector((state) =>
    selectSupplierNameFromId(state, params.supplierId)
  );

  const formatDate = (dateString) => {
    if (!dateString) return "-";
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  return (
    <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer(false)}>
      <Box
        sx={{ width: 800, padding: 5, paddingLeft: 6, paddingRight: 6 }}
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        <Box>
          <Typography variant="h4" sx={{ mb: 4, mt: 2 }}>
            More Information
          </Typography>
          <Divider sx={{ mb: 4 }} />

          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={6}>
              <Typography sx={ComponentStyles.fieldName}>Supply Chain Status</Typography>
              <Typography sx={ComponentStyles.fieldValue}>
                {factoryData?.metadata?.supplyChainStatus || "-"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={ComponentStyles.fieldName}>Compliance Status</Typography>
              <Typography sx={ComponentStyles.fieldValue}>
                {factoryData?.metadata?.complianceStatus || "-"}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography sx={ComponentStyles.fieldName}>Social Compliance Contact Person</Typography>
              <Typography sx={ComponentStyles.fieldValue}>
                Name: {factoryData?.metadata?.socialComplianceContactPerson?.name || "-"}
              </Typography>
              <Typography sx={ComponentStyles.fieldValue}>
                Email: {factoryData?.metadata?.socialComplianceContactPerson?.email || "-"}
              </Typography>
              <Typography sx={ComponentStyles.fieldValue}>
                Phone: {factoryData?.metadata?.socialComplianceContactPerson?.phone || "-"}
              </Typography>
            </Grid>
            {/* <Grid item xs={6}>
              <Typography sx={ComponentStyles.fieldName}>Language</Typography>
              <Typography sx={ComponentStyles.fieldValue}>
                {factoryData?.metadata?.facilityContactLanguages?.join(", ") || "-"}
              </Typography>
            </Grid> */}

            <Grid item xs={6}>
              <Typography sx={ComponentStyles.fieldName}>Audit Type</Typography>
              <Typography sx={ComponentStyles.fieldValue}>
                {factoryData?.metadata?.auditType || "-"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={ComponentStyles.fieldName}>Auditing Firm</Typography>
              <Typography sx={ComponentStyles.fieldValue}>
                {factoryData?.metadata?.auditingFirm || "-"}
              </Typography>
            </Grid>

            {/* <Grid item xs={6}>
              <Typography sx={ComponentStyles.fieldName}>Production Processes</Typography>
              <Typography sx={ComponentStyles.fieldValue}>
                {factoryData?.metadata?.processInfo?.productionProcesses?.join(", ") || "-"}
              </Typography>
            </Grid> */}
            <Grid item xs={6}>
              <Typography sx={ComponentStyles.fieldName}>Production Contract Type</Typography>
              <Typography sx={ComponentStyles.fieldValue}>
                {factoryData?.metadata?.processInfo?.productionContractType || "-"}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography sx={ComponentStyles.fieldName}>Supplier Type</Typography>
              <Typography sx={ComponentStyles.fieldValue}>
                {factoryData?.metadata?.processInfo?.supplierType || "-"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={ComponentStyles.fieldName}>Average Units Produced Per Week</Typography>
              <Typography sx={ComponentStyles.fieldValue}>
                {factoryData?.metadata?.avgUnitsProducedPerWeek || "-"}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography sx={ComponentStyles.fieldName}>Minimum Order Quantity</Typography>
              <Typography sx={ComponentStyles.fieldValue}>
                {factoryData?.metadata?.minOrderQty || "-"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={ComponentStyles.fieldName}>Social Assessment Payer</Typography>
              <Typography sx={ComponentStyles.fieldValue}>
                {factoryData?.metadata?.socialAssessmentInfo?.socialAssessmentPayer || "-"}
              </Typography>
            </Grid>

            {/* <Grid item xs={6}>
              <Typography sx={ComponentStyles.fieldName}>Social Assessment Types</Typography>
              <Typography sx={ComponentStyles.fieldValue}>
                {factoryData?.metadata?.socialAssessmentInfo?.socialAssessmentTypes?.join(", ") || "-"}
              </Typography>
            </Grid> */}
            <Grid item xs={6}>
              <Typography sx={ComponentStyles.fieldName}>Last Social Assessment Payer</Typography>
              <Typography sx={ComponentStyles.fieldValue}>
                {factoryData?.metadata?.socialAssessmentInfo?.lastSocialAssessmentPayer || "-"}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography sx={ComponentStyles.fieldName}>Last Social Assessment Type</Typography>
              <Typography sx={ComponentStyles.fieldValue}>
                {factoryData?.metadata?.socialAssessmentInfo?.lastSocialAssessmentType || "-"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={ComponentStyles.fieldName}>Business License Number</Typography>
              <Typography sx={ComponentStyles.fieldValue}>
                {factoryData?.metadata?.businessLicenseInfo?.licenseNo || "-"}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography sx={ComponentStyles.fieldName}>Business License Expiry Date</Typography>
              <Typography sx={ComponentStyles.fieldValue}>
                {formatDate(factoryData?.metadata?.businessLicenseInfo?.licenseExpDate)}
              </Typography>
            </Grid>
          </Grid>

          <Divider sx={{ width: '100%', my: 4 }} />

          <Grid container spacing={2}>
            <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography sx={ComponentStyles.fieldName}>
                Social Responsibility Sign Off
              </Typography>
              <Checkbox
                checked={factoryData?.metadata?.socialResponsibilitySignOff === true}
                disabled
              />
            </Grid>
            <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography sx={ComponentStyles.fieldName}>
                Environmental Responsibility Sign Off
              </Typography>
              <Checkbox
                checked={factoryData?.metadata?.environmentalResponsibilitySignOff === true}
                disabled
              />
            </Grid>

            <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography sx={ComponentStyles.fieldName}>
                FLA Wage Data Collection
              </Typography>
              <Checkbox
                checked={factoryData?.metadata?.fla.flaWageDataCollectionCheck === true}
                disabled
              />
            </Grid>
            <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography sx={ComponentStyles.fieldName}>
                Surveillance Needed?
              </Typography>
              <Checkbox
                checked={factoryData?.metadata?.facilityChecks.surveillanceNeedCheck === true}
                disabled
              />
            </Grid>

            <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography sx={ComponentStyles.fieldName}>
                Adopted Code of Conduct?
              </Typography>
              <Checkbox
                checked={factoryData?.metadata?.facilityChecks.cocCheck === true}
                disabled
              />
            </Grid>
            <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography sx={ComponentStyles.fieldName}>
                COVID-19 OSHA Compliance
              </Typography>
              <Checkbox
                checked={factoryData?.metadata?.facilityChecks.covid19OshaComplianceCheck === true}
                disabled
              />
            </Grid>

            <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography sx={ComponentStyles.fieldName}>
                Air Permit
              </Typography>
              <Checkbox
                checked={factoryData?.metadata?.facilityChecks.airPermitCheck === true}
                disabled
              />
            </Grid>
            <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography sx={ComponentStyles.fieldName}>
                Site Owned?
              </Typography>
              <Checkbox
                checked={factoryData?.metadata?.facilityChecks.siteOwnedCheck === true}
                disabled
              />
            </Grid>

            <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography sx={ComponentStyles.fieldName}>
                Worker Committee
              </Typography>
              <Checkbox
                checked={factoryData?.metadata?.facilityChecks.workerCommitteeCheck === true}
                disabled
              />
            </Grid>
            <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography sx={ComponentStyles.fieldName}>
                Subcontractors
              </Typography>
              <Checkbox
                checked={factoryData?.metadata?.facilityChecks.subcontractorsCheck === true}
                disabled
              />
            </Grid>

            <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography sx={ComponentStyles.fieldName}>
                Environmental Permit Compliance
              </Typography>
              <Checkbox
                checked={factoryData?.metadata?.facilityChecks.environmentalPermitComplianceCheck === true}
                disabled
              />
            </Grid>
            <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography sx={ComponentStyles.fieldName}>
                Emergency Spill Response Plan
              </Typography>
              <Checkbox
                checked={factoryData?.metadata?.facilityChecks.emergencySpillResponsePlanCheck === true}
                disabled
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Drawer>
  );
};

export default FacilityInformationDrawer;
