import React from "react";
import { Box, Typography, Select, MenuItem } from "@mui/material";

const IRSSDropdown = ({ currentStatus, onChange }) => {
  return (
    <Box sx={{ mb: 2 }}>
      <Typography variant="h6" sx={{ fontWeight: 600 }}>
        IRSS Status
      </Typography>
      <Select
        value={currentStatus}
        onChange={(e) => onChange(e.target.value)}
        sx={{ width: "100%", mt: 1 }}
      >
        <MenuItem value="Waiting to be enrolled">Waiting to be enrolled</MenuItem>
        <MenuItem value="Currently enrolled">Currently enrolled</MenuItem>
        <MenuItem value="Graduated">Graduated</MenuItem>
      </Select>
    </Box>
  );
};

export default IRSSDropdown;