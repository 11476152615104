import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Box,
  Card,
  Divider,
  CardContent,
  Tooltip,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { selectFactoryData } from "../../selectors/factory.selector";
import { selectAuditData } from "../../selectors/audit.selector";
import { getFactoryById } from "../../reducers/factory.reducer";
import { useParams } from "react-router-dom";
import moment from "moment";
import AuditInformationEditModal from "./EditModals/editAuditInformation.component";
import { selectEditModeState } from "../../selectors/misc.selector";
import EditIcon from "@mui/icons-material/Edit";
import { AuditStyles, ComponentStyles } from "../../styles";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const AuditInformation = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const selectedAuditData = useSelector(selectAuditData);
  const selectedFactoryData = useSelector(selectFactoryData);
  const [factoryData, setFactoryData] = useState(selectedFactoryData);
  const [isEditingInternal, setIsEditingInternal] = useState(false);

  const editMode = useSelector(selectEditModeState);

  useEffect(() => {
    if (
      selectedAuditData &&
      ((selectedFactoryData?._id &&
        selectedAuditData?.factoryId !== selectedFactoryData?._id) ||
        !selectedFactoryData)
    ) {
      dispatch(
        getFactoryById({
          supplierId: params.supplierId,
          factoryId: selectedAuditData.factoryId,
        })
      );
    }
    setFactoryData(selectedFactoryData);
  }, [selectedFactoryData, selectedAuditData]);

  const toggleEditInternal = () => {
    setIsEditingInternal(!isEditingInternal);
  };

  return (
    factoryData &&
    (!editMode ? (
      <Box sx={AuditStyles.containerBox}>
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          sx={AuditStyles.containerGrid}
        >
          <Grid item>
            <Typography
              sx={{
                ...AuditStyles.headingsNameBold700,
                display: "inline-block",
              }}
            >
              Audit Information
            </Typography>
          </Grid>
          {editMode && (
            <EditIcon
              fontSize={"2"}
              sx={ComponentStyles.editButton}
              onClick={toggleEditInternal}
            />
          )}
        </Grid>

        <Grid container spacing={2} sx={AuditStyles.containerGrid}>
          <Grid item xs={6}>
            <Card variant="outlined" style={AuditStyles.editModeCard}>
              <CardContent
                style={{
                  padding: "4px",
                  paddingLeft: "32px",
                  paddingRight: selectedAuditData?.metadata?.originalAuditScore
                    ? "18px"
                    : "32px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography style={AuditStyles.headingsNameBold700}>
                  Reformation Score
                </Typography>
                <Divider
                  orientation="vertical"
                  flexItem
                  style={{ margin: "16px 16px" }}
                />
                <Typography style={AuditStyles.bigHeadingFont30}>
                  {selectedAuditData?.risk?.auditScore ?? "-"}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card variant="outlined" style={AuditStyles.editModeCard}>
              <CardContent
                style={{
                  padding: "4px",
                  paddingLeft: "32px",
                  paddingRight: selectedAuditData?.metadata?.originalAuditScore
                    ? "18px"
                    : "32px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography style={AuditStyles.headingsNameBold700}>
                  Original Audit Score
                </Typography>
                <Divider
                  orientation="vertical"
                  flexItem
                  style={{ margin: "16px 16px" }}
                />
                <Typography style={AuditStyles.bigHeadingFont30}>
                  {selectedAuditData?.metadata?.originalAuditScore ?? "-"}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          
        </Grid>

        {/* Company Address */}
        <Grid container spacing={2} sx={AuditStyles.containerGrid}>
          <Grid item xs={4}>
            <Typography sx={AuditStyles.fieldName}>Audit Scheme</Typography>
            <Typography sx={AuditStyles.fieldValue}>
              {selectedAuditData?.metadata?.auditType || "-"}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={AuditStyles.fieldName}>Audit Start Date</Typography>
            <Typography sx={AuditStyles.fieldValue}>
              {selectedAuditData?.metadata?.auditStartDate
                ? moment(selectedAuditData.metadata.auditStartDate).format(
                    "MMM. DD, YYYY"
                  )
                : "-"}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={AuditStyles.fieldName}>End Date</Typography>
            <Typography sx={AuditStyles.fieldValue}>
              {selectedAuditData?.metadata?.auditEndDate
                ? `${moment(selectedAuditData.metadata.auditEndDate).format(
                    "MMM. DD, YYYY"
                  )}`
                : "-"}
            </Typography>
          </Grid>
        </Grid>
        {/* Company information */}
        <Grid container spacing={2} sx={AuditStyles.containerGrid}>
          <Grid item xs={4}>
            <Typography sx={AuditStyles.fieldName}>Audit Scheduler</Typography>
            <Typography sx={AuditStyles.fieldValue}>
              {selectedAuditData?.metadata?.auditScheduler || "-"}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={AuditStyles.fieldName}>Audit Funder</Typography>
            <Typography sx={AuditStyles.fieldValue}>
              {selectedAuditData?.metadata?.auditFunder || "-"}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={AuditStyles.fieldName}>Auditor Firm</Typography>
            <Typography sx={AuditStyles.fieldValue}>
              {selectedAuditData?.metadata?.auditFirm || "-"}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={AuditStyles.containerGrid}>
          <Grid item xs={4}>
            <Typography sx={AuditStyles.fieldName}>Auditor Name</Typography>
            <Typography sx={AuditStyles.fieldValue}>
              {selectedAuditData?.auditor || "-"}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={AuditStyles.fieldName}>
              Language (Management)
            </Typography>
            <Typography sx={AuditStyles.fieldValue}>
              {selectedAuditData?.metadata?.primaryLanguage ?? "-"}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={AuditStyles.fieldName}>
              Language (Workers)
            </Typography>
            <Typography sx={AuditStyles.fieldValue}>
              {selectedAuditData?.metadata?.workersLanguage ?? "-"}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    ) : (
      <AuditInformationEditModal
        open={isEditingInternal}
        onClose={() => setIsEditingInternal(false)}
        factoryData={selectedFactoryData}
        auditData={selectedAuditData}
      />
    ))
  );
};

export default AuditInformation;
