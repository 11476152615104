import React, { useEffect, useState } from "react";
import { Grid, Card, CardContent, Typography, Button } from "@mui/material";
import { useSelector } from "react-redux";
import { selectSupplierData } from "../../selectors/supplier.selector";
import SupplierInformationEditModal from "./EditModals/editSupplierInformation.component";
import EditIcon from "@mui/icons-material/Edit";
import { selectEditModeState } from "../../selectors/misc.selector";
import SupplierInformationDrawer from "./supplierInformationDrawer.component";
import { ComponentStyles, SupplierStyles } from "../../styles";
import SupplierTierMapDrawer from "./supplierTierMapDrawer.component";

const SupplierInformation = () => {
  const supplierData = useSelector(selectSupplierData);

  const [isEditing, setIsEditing] = useState(false);
  const editMode = useSelector(selectEditModeState);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isTierMapDrawerOpen, setIsTierMapDrawerOpen] = useState(false);

  const toggleEdit = () => {
    setIsEditing(!isEditing);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsDrawerOpen(open);
  };

  const toggleTierMapDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsTierMapDrawerOpen(open);
  };

  const copyToClipboard = () => {
    const orgID = supplierData.organisationId;
    const supplierID = supplierData._id;
    navigator.clipboard.writeText("https://q-auditsense-1086719075712.us-central1.run.app/" + orgID + "/" + supplierID)
      .then(() => {
        alert("URL copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  return (
    <Card style={SupplierStyles.supplierInformationCard}>
      <SupplierInformationEditModal
        open={isEditing}
        onClose={() => setIsEditing(false)}
        supplierData={supplierData}
      />

      {supplierData && (
        <CardContent sx={{ minHeight: "305px" }}>
          <Grid container justifyContent="space-between" sx={{ mb: 2 }}>
            <Grid item sx={{ alignContent: "center"}}>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: 16,
                  color: "#344054",
                  display: "inline-block",
                }}
              >
                General Supplier Information
              </Typography>
              {editMode && (
                <EditIcon
                  fontSize={"2"}
                  sx={ComponentStyles.editButton}
                  onClick={toggleEdit}
                />
              )}
            </Grid>

            <Grid item>
              <Button
                variant="outlined"
                onClick={toggleDrawer(true)}
                sx={{
                  color: "#6172F3",
                  "&:hover": {
                    color: "white",
                    backgroundColor: "#5667e2",
                  },
                  textTransform: "none",
                  width: "150px",
                  height: "40px",
                  borderRadius: "8px",
                  mr: 1
                }}
              >
                More Information
              </Button>

              <Button
                variant="outlined"
                onClick={toggleTierMapDrawer(true)}
                sx={{
                  color: "#6172F3",
                  "&:hover": {
                    color: "white",
                    backgroundColor: "#5667e2",
                  },
                  textTransform: "none",
                  width: "100px",
                  height: "40px",
                  borderRadius: "8px",
                  mr: 1
                }}
              >
                Tier Map
              </Button>
              <Button
                variant="outlined"
                onClick={copyToClipboard}
                sx={{
                  color: "#6172F3",
                  "&:hover": {
                    color:"white",
                    backgroundColor: "#5667e2",
                  },
                  textTransform: "none",
                  width: "150px",
                  height: "40px",
                  borderRadius: "8px",
                  mr:"-24px"
                }}
              >
                Generate SAQ
              </Button>
            </Grid>
          </Grid>
          {/* Company Address */}
          <Grid container justifyContent="space-between" sx={{ mb: 2 }}>
            <Grid item>
              <Typography sx={ComponentStyles.fieldName}>
                {" "}
                Company Address
              </Typography>
              <Typography
                sx={{
                  ...ComponentStyles.fieldValue,
                  wordBreak: "break-word",
                }}
              >
                {supplierData?.metadata?.companyAddress}
              </Typography>
            </Grid>

            <Grid item>
              <Typography sx={ComponentStyles.fieldName}>
                {" "}
                Supplier ID
              </Typography>
              <Typography
                sx={{
                  ...ComponentStyles.fieldValue,
                  wordBreak: "break-word",
                }}
              >
                {supplierData?.metadata?.uniqueSupplierId}
              </Typography>
            </Grid>
          </Grid>
          {/* Company information */}
          <Grid container spacing={1} justifyContent="space-between">
            <Grid item xs={3}>
              <Typography sx={ComponentStyles.fieldName}>
                Contact Person
              </Typography>
              <Typography
                sx={{
                  ...ComponentStyles.fieldValue,
                  wordBreak: "break-word",
                }}
              >
                {supplierData?.metadata?.contactPerson?.name || "-"}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={ComponentStyles.fieldName}>Position</Typography>
              <Typography
                sx={{
                  ...ComponentStyles.fieldValue,
                  wordBreak: "break-word",
                }}
              >
                {supplierData?.metadata?.contactPerson?.position || "-"}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={ComponentStyles.fieldName}>Phone</Typography>
              <Typography
                sx={{
                  ...ComponentStyles.fieldValue,
                  wordBreak: "break-word",
                }}
              >
                {supplierData?.metadata?.contactPerson?.phone || "-"}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={ComponentStyles.fieldName}>Email</Typography>
              <Typography
                sx={{
                  ...ComponentStyles.fieldValue,
                  wordBreak: "break-word",
                }}
              >
                {supplierData?.metadata?.contactPerson?.email || "-"}
              </Typography>
            </Grid>
          </Grid>

        </CardContent>
      )}
      <SupplierInformationDrawer
        supplierData={supplierData}
        isDrawerOpen={isDrawerOpen}
        toggleDrawer={toggleDrawer}
      />
      <SupplierTierMapDrawer
        supplierData={supplierData}
        isDrawerOpen={isTierMapDrawerOpen}
        toggleDrawer={toggleTierMapDrawer}
      />
    </Card>

  );
};

export default SupplierInformation;
