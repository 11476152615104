import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Divider,
  Tooltip,
  IconButton,
  Button,
} from "@mui/material";
import SortIcon from "@mui/icons-material/Sort";
import { selectFactoryIssuesData } from "../../selectors/factory.selector";
import { useSelector } from "react-redux";
import { selectAuditIssuesData, selectAuditData } from "../../selectors/audit.selector";
import { AuditStyles, ComponentStyles } from "../../styles";
const AuditIssuesTable = () => {
  const issuesData = useSelector(selectAuditIssuesData);
  const selectedAuditData = useSelector(selectAuditData);
  const auditIsProcessed = selectedAuditData?.metadata?.severityCategories ? true : false;


  const [filteredIssues, setFilteredIssues] = useState(
    issuesData.detailedIssues || []
  );
  useEffect(() => {
    setFilteredIssues(issuesData.detailedIssues || []);
  }, [issuesData.detailedIssues]);
  const [isSorted, setIsSorted] = useState(false);
  const handleSort = () => {
    const sortedData = [...filteredIssues].sort((a, b) => {
      if (isSorted) {
        return a.category.localeCompare(b.category);
      } else {
        return b.category.localeCompare(a.category);
      }
    });
    setFilteredIssues(sortedData);
    setIsSorted(!isSorted);
  };

  const handleFilter = (key) => {
    const filteredData = issuesData.detailedIssues.filter(
      (issue) => issue[key] > 0
    );
    setFilteredIssues(filteredData);
  };

  return (
    <Paper elevation={1} sx={AuditStyles.issuesTableContainerPaper}>
      {auditIsProcessed ? (
        <Box sx={{ height: 330, overflow: "auto" }}>
          <Table stickyHeader>
            <TableHead sx={{ zIndex: 1 }}>
              <TableCell
                sx={{
                  ...AuditStyles.issuesTableCell,
                  px: 0,
                }}
              ></TableCell>
              <TableCell sx={AuditStyles.issuesTableCell}>
                <Typography sx={ComponentStyles.headingsName}>Total</Typography>
              </TableCell>
              <TableCell sx={AuditStyles.issuesTableCell}>
                <Typography sx={ComponentStyles.headingsName}>Closed</Typography>
              </TableCell>
              <TableCell sx={AuditStyles.issuesTableCell}>
                <Typography sx={ComponentStyles.headingsName}>Open</Typography>
              </TableCell>
              <TableCell sx={AuditStyles.issuesTableCell}>
                <Typography sx={ComponentStyles.headingsName}>
                  Past Due
                </Typography>
              </TableCell>
            </TableHead>
            <TableRow>
              <TableCell sx={AuditStyles.issuesTableCell}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box sx={ComponentStyles.alignBoxItemsCenter}>
                    <Typography sx={ComponentStyles.headingsName}></Typography>
                  </Box>
                </Box>
              </TableCell>
              <TableCell sx={AuditStyles.issuesTableCellWithSolidRightBorder}>
                <Box sx={ComponentStyles.alignBoxItemsCenter}>
                  <Typography sx={ComponentStyles.headingsName}>
                    {issuesData.total}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell sx={AuditStyles.issuesTableCellWithDottedRightBorder}>
                <Box sx={ComponentStyles.alignBoxItemsCenter}>
                  <Typography sx={ComponentStyles.headingsName}>
                    {issuesData.issuesSummary.totalClosed}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell sx={AuditStyles.issuesTableCellWithDottedRightBorder}>
                <Box sx={ComponentStyles.alignBoxItemsCenter}>
                  <Typography sx={ComponentStyles.headingsName}>
                    {issuesData.issuesSummary.open}
                  </Typography>
                </Box>
              </TableCell>

              <TableCell sx={AuditStyles.issuesTableCell}>
                <Box sx={ComponentStyles.alignBoxItemsCenter}>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: 15,
                      color: "#344054",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {issuesData.issuesSummary.pastDue}
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>

            <TableBody>
              {filteredIssues.map((issue, index) => (
                <TableRow key={index} sx={{ cursor: "default", height: "40px" }}>
                  <TableCell
                    sx={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: issue.color,
                      maxWidth: "150px",
                      pr: 0,
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      cursor: "default",
                      borderBottom: "none",
                      py: 0.5,
                    }}
                  >
                    {issue.category.length > 15 ? (
                      <Tooltip
                        title={issue.category}
                        placement="top"
                        sx={{ width: "32px", color: issue.color }}
                      >
                        <span>{issue.category}</span>
                      </Tooltip>
                    ) : (
                      issue.category
                    )}
                  </TableCell>

                  <TableCell
                    sx={{
                      ...AuditStyles.issuesTableCellWithSolidRightBorder,
                      borderBottom: "none",
                      py: 0.5,
                    }}
                  >
                    <Box sx={ComponentStyles.alignBoxItemsCenter}>
                      <Typography sx={{ ...ComponentStyles.headingsName, fontSize: 14 }}>
                        {issue.open + issue.totalClosed || "0"}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      ...AuditStyles.issuesTableCellWithDottedRightBorder,
                      borderBottom: "none",
                      py: 0.5,
                    }}
                  >
                    <Box sx={ComponentStyles.alignBoxItemsCenter}>
                      <Typography sx={{ ...ComponentStyles.headingsName, fontSize: 14 }}>
                        {issue.totalClosed || "0"}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      ...AuditStyles.issuesTableCellWithDottedRightBorder,
                      borderBottom: "none",
                      py: 0.5,
                    }}
                  >
                    <Box sx={ComponentStyles.alignBoxItemsCenter}>
                      <Typography sx={{ ...ComponentStyles.headingsName, fontSize: 14 }}>
                        {issue.open || "0"}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: 500,
                      fontSize: 13,
                      borderBottom: "none",
                      py: 0.5,
                    }}
                  >
                    <Box sx={ComponentStyles.alignBoxItemsCenter}>
                      <Typography sx={{ ...ComponentStyles.headingsName, fontSize: 14 }}>
                        {issue.pastDue || "0"}
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      ) : (
        <Typography sx={{ p: 10, textAlign: 'center' }}>
         The findings table will be avaliabled after the audit is processed.
        </Typography>
      )}
    </Paper>
  );
};

export default AuditIssuesTable;
