import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Box,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectFactoryData } from "../../selectors/factory.selector";
import { getListOfAllAudits, getAuditById } from "../../reducers/audit.reducer";
import RiskCard from "../riskCard.component";
import FacilityIssuesCard from "./facilityIssuesCard.component";
import TwoLineCard from "../twoLineCard.component";
import { FacilityStyles, AuditStyles } from "../../styles";
import moment from "moment";

const getColorForRating = (rating) => {
  switch (rating) {
    case "Green (A)":
      return AuditStyles.auditRatingColors.green;
    case "Yellow (B)":
      return AuditStyles.auditRatingColors.yellow;
    case "Orange (C)":
      return AuditStyles.auditRatingColors.orange;
    case "Red (D)":
      return AuditStyles.auditRatingColors.red;
    default:
      return "black";
  }
};

const FactoryMostRecentAuditDetails = ({ selectedFactory }) => {
  const auditEndDate = selectedFactory?.latestAudit?.metadata?.auditEndDate;
  const nextAuditDate = selectedFactory?.metadata?.assessmentDates?.nextAssessmentWindowDateStart;
  const formattedNextAuditDate = nextAuditDate ? moment(nextAuditDate).format('MMM. DD, YYYY') : "-";
  const daysUntilNextAudit = nextAuditDate ? moment(nextAuditDate).diff(moment(), 'days') : null;
  console.log("daysUntilNextAudit")
  const nextAuditType = selectedFactory?.metadata?.nextAuditType;
  const rating = selectedFactory?.latestAudit?.risk?.auditRating || "Coming Soon.";


  return (
    <>
      <Typography sx={FacilityStyles.mostRecentAuditHeading}>
        Details from Most Recent Audit on <u>{auditEndDate}</u>
      </Typography>
      <Grid container spacing={4} >
        <Grid item xs={12} md={4} container direction="column" spacing={2} sx={{ height: '100%' }}>
        <Grid item sx={{ flexGrow: 1.1 }}> 
          <TwoLineCard
            title="Rating"
            nextAuditDate={rating}
            textColor={getColorForRating(rating)}
          />
          </Grid>
          <Grid item sx={{ flexGrow: 1.1 }}> 
          <TwoLineCard
            title="Audit Type"
            nextAuditDate={selectedFactory?.latestAudit?.metadata?.auditType || "-"}
          />
          </Grid>
        </Grid>
        <Grid item sm={12} md={8}>
          <FacilityIssuesCard latestAuditData={selectedFactory.latestAudit}/>
        </Grid>
        <Grid item xs={12}>
        <TwoLineCard
            title="Next Audit Due Date and Type"
            nextAuditDate={
              <>
                {formattedNextAuditDate}
                {daysUntilNextAudit !== null && (
                <Box
                  component="span"
                  sx={{
                    marginLeft: 1,
                    padding: "3px 8px",
                    fontSize: "18px",
                    fontWeight: 500,
                    borderRadius: "4px",
                    border: "1px solid #D0D5DD",
                    background: "white",
                    color: "#344054",
                    display: "inline-block",
                    verticalAlign: "middle",
                  }}
                >
                  {daysUntilNextAudit !== null ? `In ${daysUntilNextAudit} Days` : ""}
                </Box>
                )}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {` for`}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {selectedFactory?.metadata?.nextAuditType || "-"}
              </>
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

export default FactoryMostRecentAuditDetails;
