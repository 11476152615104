import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Box,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectFactoryData } from "../../selectors/factory.selector";
import { getListOfAllAudits, getAuditById } from "../../reducers/audit.reducer";
import RiskCard from "../riskCard.component";
import FacilityIssuesCard from "./facilityIssuesCard.component";
import PastCAP from "./factoryActionNeeded.component";
import FactoryActionNeeded from "./factoryActionNeeded.component";
import TwoLineCard from "../twoLineCard.component";
import { FacilityStyles } from "../../styles";


const FactoryMostRecentAuditDetails = ({ selectedFactory }) => {

  return (
    <>
      <Typography sx={FacilityStyles.mostRecentAuditHeading}>
        Details from Most Recent Audit
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={7}>
          <FacilityIssuesCard latestAuditData={selectedFactory.latestAudit}/>
        </Grid>
        <Grid item xs={5}>
          <TwoLineCard
            title="Audit Score"
            nextAuditDate={selectedFactory?.latestAudit?.risk?.auditScore || "Coming Soon."}
          />
          <TwoLineCard
            title="Next Audit Due Date"
            nextAuditDate={selectedFactory?.metadata?.nextAuditDate || "-"}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default FactoryMostRecentAuditDetails;
